/* eslint-disable import/no-extraneous-dependencies */
import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* searchReceiver({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params
        ? `/customers/list?limit=50&${payload.params}&include=region,orderLatest,orders,orderBooking,debts`
        : '/customers/list?limit=50&include=region,orderLatest,orders,orderBooking,debts',
      {
        params: {
          timestamp: new Date().getTime(),
        },
      },
    ));
    yield put(Actions.searchReceiverSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.searchReceiverFailure(messages));
    }
  }
}

function* regionReceiver() {
  try {
    const response = yield call(() => axiosMicro.get('/regions?limit=1000', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.regionReceiverSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.regionReceiverFailure(messages));
    }
  }
}

function* searchProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params
        ? `/products?include=type&limit=1000&${payload.params}`
        : '/products?include=type&limit=1000',
    ));
    yield put(Actions.searchProductSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.searchProductFailure(messages));
    }
  }
}

function* searchProductType({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params
        ? `/product-types?limit=1000&${payload.params}`
        : '/product-types?limit=1000',
    ));
    yield put(Actions.searchProductTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.searchProductTypeFailure(messages));
    }
  }
}

function* getListOrders({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/orders', {
      params: {
        ...(payload || {}),
        timestamp: new Date().getTime(),
        include: 'statuses,customer,gift,voucher,transport,warehouse,order_waiting,exports,printItems,shipping,orderSplits,order',
      },
    }));
    yield put(Actions.getListOrdersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListOrdersFailure(messages));
    }
  }
}

function* getAllOrders({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/orders?include=statuses,customer,gift,voucher&limit=0&${payload.params}` : '/orders?include=statuses,customer,gift,voucher&limit=0', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getAllOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrderFailure(messages));
    }
  }
}

function* getAllOrderWaitings({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/order-waitings?include=shipping,order,customer&${payload.params}` : '/orders-waitings?include=shipping,order,customer', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getAllOrderWaitingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrderWaitingFailure(messages));
    }
  }
}

function* getAllRegionGroup() {
  try {
    const response = yield call(() => axiosMicro.get('/region-groups?include=regions', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getAllRegionGroupOrderSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllRegionGroupOrderFailure(messages));
    }
  }
}

function* createOrder({ payload }) {
  try {
    const waiting_order_id = payload?.waiting_order_id;
    payload?.waiting_order_id && delete payload.waiting_order_id;
    const response = yield call(() => axiosMicro.post('/orders?include=customer,shipping', payload));
    if (response?.status === 201) {
      const orderData = response?.data;
      if (waiting_order_id) {
        try {
          const updateResponse = yield call(() => axiosMicro.patch(`/order-waitings/${waiting_order_id}`, {
            order_id: orderData?.data?.id,
          }));
          if (updateResponse?.status === 200) {
            yield put(Actions.createOrderSuccess(response.data));
          }
        } catch (e) {
          if (e?.response?.data) {
            const messages = e.response.data;
            yield put(Actions.createOrderFailure(messages));
          }
        }
      } else {
        yield put(Actions.createOrderSuccess(response.data));
      }
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createOrderFailure(messages));
    }
  }
}

function* updateOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/orders/${payload.id}`, payload.params));
    yield put(Actions.updateOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateOrderFailure(messages));
    }
  }
}

function* deleteOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/orders/${payload}`));
    yield put(Actions.deleteOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteOrderFailure(messages));
    }
  }
}

function* getAllShippingOrder() {
  try {
    const response = yield call(() => axiosMicro.get('/shippings?limit=0'));
    yield put(Actions.getAllShippingOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllShippingOrderFailure(messages));
    }
  }
}

function* updateShippingOrder({ payload }) {
  try {
    const { id, ...data } = payload;
    yield call(() => axiosMicro.patch(`/orders/${payload.id}`, data));
    yield put(Actions.updateShippingOrderSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateShippingOrderFailure(messages));
    }
  }
}

function* updateShippingIdOrder({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/orders/${payload.id}`, payload));
    yield put(Actions.updateShippingIdOrderSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateShippingIdOrderFailure(messages));
    }
  }
}

function* printedOrder({ payload }) {
  try {
    const { id, ...data } = payload.dataUpdateStatus;
    if (payload.from !== 'warehouse') {
      if (payload.requestRestPrint) {
        yield call(() => axiosMicro.patch(`/orders/print/${id}/reset`, { reset: false }));
      } else {
        yield call(() => axiosMicro.post(`/orders/${id}/print`, data));
        yield call(() => axiosMicro.post('/orders/print', payload.dataExport));
      }
    } else {
      yield call(() => axiosMicro.post('/orders/print', { ...payload.dataExport, reset: true }));
    }
    yield put(Actions.printedOrderSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.printedOrderFailure(messages));
    }
  }
}

function* updateStatusOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/orders/${payload.id}`, payload.params));
    yield put(Actions.updateStatusOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateStatusOrderFailure(messages));
    }
  }
}

function* updateWarehouseOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/orders/${payload.id}`, payload));
    yield put(Actions.updateWarehouseOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateWarehouseOrderFailure(messages));
    }
  }
}

function* getAllWarehouse() {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses?limit=0'));
    if (response?.status === 200) {
      yield put(Actions.getAllWareHouseOrderSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllWareHouseOrderFailure(messages));
    }
  }
}

function* getOrderLate() {
  try {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - 5);
    const response = yield call(() => axiosMicro.get('/orders', {
      params: {
        timestamp: new Date().getTime(),
        include: 'shipping,user,statuses,customer,warehouse,order_waiting',
        sortedBy: 'desc',
        orderBy: 'id',
        searchDate: `,${moment(newDate).format('YYYY-MM-DD')}|updated_at`,
        status: 'delivery',
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.getOrderLateSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrderLateFailure(messages));
    }
  }
}

function* updateStatusMulti({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch('/orders/status', payload));
    if (response?.status === 204) {
      yield put(Actions.updateStatusMultiSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateStatusMultiFailure(messages));
    }
  }
}

function* getAllOrdersInChartRevenue({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/orders?include=shipping,user,statuses,customer,team&limit=0&${payload.params}` : '/orders?include=shipping,user,statuses,customer,team&limit=0'));
    yield put(Actions.getAllOrderInChartRevenueSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrderInChartRevenueFailure(messages));
    }
  }
}

function* getAllOrdersInChartGrow({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/orders?include=shipping,user,statuses,customer,team&limit=0&${payload.params}` : '/orders?include=shipping,user,statuses,customer,team&limit=0'));
    yield put(Actions.getAllOrderInChartGrowSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrderInChartGrowFailure(messages));
    }
  }
}

function* updateContemporaryExpense({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch('/orders/expense', { orders: payload }, {
      params: {
        skipCache: 1,
      },
    }));
    if (response?.status === 200) {
      yield put(Actions.updateContemporaryExpenseSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateContemporaryExpenseFailure(messages));
    }
  }
}

function* getAllOrderFromApp({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/sales/orders?${payload.params}` : '/sales/orders?limit=50', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getAllOrderFromAppSuccess(response?.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrderFromAppFailure(messages));
    }
  }
}

function* approveOrderFromApp({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/sales/orders/${payload.id}/accept`, payload.params));
    if (response?.status === 200) {
      yield put(Actions.approveOrderFromAppSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.approveOrderFromAppFailure(messages));
    }
  }
}

function* refuseOrderFromApp({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/sales/orders/${payload.id}/cancel`, { reason: payload.reason }));
    if (response?.status === 200) {
      yield put(Actions.refuseOrderFromAppSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.refuseOrderFromAppFailure(messages));
    }
  }
}

function* rollBackOrder({ payload }) {
  try {
    yield call(() => axiosMicro.post('/orders/rollback', payload));
    yield put(Actions.rollBackOrderSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.rollBackOrderFailure(messages));
    }
  }
}

function* printOrderAndRedirect({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/transports/print${payload.params}`));
    yield put(Actions.printOrderAndRedirectSuccess(response?.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.printOrderAndRedirectFailure(messages));
    }
  }
}

function* updatePaymentOrderApp({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/sales/orders/${payload.id}`, payload.params));
    yield put(Actions.updatePaymentOrderAppSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updatePaymentOrderAppFailure(messages));
    }
  }
}

function* getLogOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/orders/logs?order_id=${payload}`));
    if (response?.status === 200) {
      yield put(Actions.getLogOrderSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getLogOrderFailure(messages));
    }
  }
}

function* exchangeOrderFromApp({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('sales/orders/voucher', payload));
    if (response?.status === 200) {
      yield put(Actions.exchangeVoucherOrderAppSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.exchangeVoucherOrderAppFailure(messages));
    }
  }
}

function* getDetailOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/orders?include=statuses,customer,gift,voucher,transport&${payload.params}` : '/orders?include=statuses,customer,gift,voucher,transport'));
    if (response?.status === 200) {
      yield put(Actions.getOrderDetailSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrderDetailFailure(messages));
    }
  }
}

function* getOrderByQrCode({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/orders/code?include=statuses,customer,gift,voucher,transport&${payload.params}`));
    if (response?.status === 200) {
      yield put(Actions.getOrderByQrCodeSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrderByQrCodeFailure(messages));
    }
  }
}

function* cancelOrder({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/orders/${payload.id}/cancel`, payload.params));
    yield put(Actions.cancelOrderSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.cancelOrderFailure(messages));
    }
  }
}

function* refundOrder({ payload }) {
  try {
    const { id, ...body } = payload;
    yield call(() => axiosMicro.patch(`/orders/${id}/refund`, body));
    yield put(Actions.refundOrderSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.refundOrderFailure(messages));
    }
  }
}

function* createSellingVoucher({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/accounting/voucher/sell', payload));
    if (response?.status === 200) {
      yield put(Actions.createSellingVoucherSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createSellingVoucherFailure(messages));
    }
  }
}

function* createPurcharseVoucher({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/accounting/voucher/purcharse', payload));
    if (response?.status === 200) {
      yield put(Actions.createPurcharseVoucherSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createPurcharseVoucherFailure(messages));
    }
  }
}

function* getListOrderStaffPacking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/staff-packing/orders?include=statuses,customer,gift,voucher,transport&${payload.params}` : '/staff-packing/orders?include=statuses,customer,gift,voucher,transport'));
    yield put(Actions.getListOrderStaffPackingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListOrderStaffPackingFailure(messages));
    }
  }
}

function* getOrderByQrCodeStaffPacking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/staff-packing/code?include=statuses,customer,gift,voucher,transport&${payload.params}`));
    if (response?.status === 200) {
      yield put(Actions.getOrderByQrCodeStaffPackingSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrderByQrCodeStaffPackingFailure(messages));
    }
  }
}

function* updateStatusOrderStaffPacking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch('/staff-packing/orders', { id: payload.id }));
    yield put(Actions.updateStatusOrderStaffPackingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateStatusOrderStaffPackingFailure(messages));
    }
  }
}

function* getPriceShippingLala({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/lalamoves/quotation', payload));
    if (response?.status === 200) {
      yield put(Actions.getPriceShippingLalaSuccess(response.data));
    }
  } catch (e) {
    if (e.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getPriceShippingLalaFailure(messages));
    }
  }
}

function* createShippingLala({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/lalamoves/order', payload));
    if (response?.status === 200) {
      yield put(Actions.createShippingLalaSuccess(response.data));
    }
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createShippingLalaFailure(messages));
    }
  }
}

function* getListOrderLalaAccepted({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/order?${payload}`));
    if (response?.status === 200) {
      yield put(Actions.getListOrderLalaAcceptedSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListOrderLalaAcceptedFailure(messages));
    }
  }
}

function* createFeeSangthuy({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/lalamoves/fee-ship', payload));
    if (response?.status === 200) {
      yield put(Actions.createFeeSangthuySuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createFeeSangthuyFailure(messages));
    }
  }
}

function* getLogLalaMove({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload.params ? `/lalamoves?include=orders,logs&${payload.params}` : '/lalamoves?include=orders,logs'));
    if (response?.status === 200) {
      yield put(Actions.getLogLalaMoveSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getLogLalaMoveFailure(messages));
    }
  }
}

function* updateFeeLalaMove({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/lalamoves/fee-surcharge', payload));
    if (response?.status === 200) {
      yield put(Actions.updateFeeLalaMoveSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateFeeLalaMoveFailure(messages));
    }
  }
}

function* createFeeLalaManual({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/lalamoves/fee-manual', payload));
    if (response?.status === 200) {
      yield put(Actions.createFeeLalaManualSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createFeeLalaManualFailure(messages));
    }
  }
}

function* cancelLalaMove({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/lalamoves/cancel', payload));
    if (response?.status === 200) {
      yield put(Actions.cancelLalaMoveSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.cancelLalaMoveFailure(messages));
    }
  }
}

function* claimPermission({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/lalamoves/edit', payload));
    if (response?.status === 201) {
      yield put(Actions.claimPermissionSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.claimPermissionFailure(messages));
    }
  }
}

function* acceptPermission({ payload }) {
  try {
    const { id, status } = payload;
    yield call(() => axiosMicro.patch(`lalamoves/edit/${id}`, { status }));
    yield put(Actions.acceptPermissionSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.acceptPermissionFailure(messages));
    }
  }
}

function* updateOrderLala({ payload }) {
  try {
    const {
      id,
      ...props
    } = payload;
    yield call(() => axiosMicro.patch(`/lalamoves/${id}`, props));
    yield put(Actions.updateOrderLalaSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateOrderLalaFailure(messages));
    }
  }
}

function* getListOrderLalaCanChange({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/orders?include=statuses,customer,gift,voucher,transport&page=1&limit=50&orderBy=id&sortedBy=desc${payload}`));
    yield put(Actions.getListOrderLalaCanChangeSuccess(response.data));
  } catch (e) {
    if (e.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListOrderLalaCanChangeFailure(messages));
    }
  }
}

function* getAllProductSizesOrder({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-sizes/list?${payload}`)
        : () => axiosMicro.get('/product-sizes/list', { params: payload }),
    );
    yield put(Actions.getAllProductSizesOrderSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductSizesOrderFailure(messages));
    }
  }
}

function* splitOrder({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/orders/${payload.id}/split`, payload.body));
    yield put(Actions.splitOrderSuccess());
  } catch (error) {
    if (error?.response.data) {
      const messages = error.response.data;
      yield put(Actions.splitOrderFailure(messages));
    }
  }
}

function* mergeOrder({ payload }) {
  try {
    yield call(() => axiosMicro.post('/orders/merge', payload));
    yield put(Actions.mergeOrderSuccess());
  } catch (error) {
    if (error?.response.data) {
      const messages = error.response.data;
      yield put(Actions.mergeOrderFailure(messages));
    }
  }
}

function* checkProductInStock({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/batches/check-instock', { params: payload }));
    yield put(Actions.checkProductInStockSuccess(response.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.checkProductInStockFailure(messages));
    }
  }
}

function* getListOrdersAll({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/orders', {
      params: payload,
    }));
    yield put(Actions.getListOrdersAllSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getListOrdersAllFailure(messages));
    }
  }
}

function* updatePriceOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/orders/${payload.id}/price`, payload.body));
    yield put(Actions.updatePriceOrderSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updatePriceOrderFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.searchReceiverRequest, searchReceiver);
  yield takeLatest(Actions.regionReceiverRequest, regionReceiver);
  yield takeLatest(Actions.searchProductRequest, searchProduct);
  yield takeLatest(Actions.searchProductTypeRequest, searchProductType);
  yield takeLatest(Actions.getListOrdersRequest, getListOrders);
  yield takeLatest(Actions.getAllOrderRequest, getAllOrders);
  yield takeLatest(Actions.createOrderRequest, createOrder);
  yield takeLatest(Actions.updateOrderRequest, updateOrder);
  yield takeLatest(Actions.deleteOrderRequest, deleteOrder);
  yield takeLatest(Actions.getAllShippingOrderRequest, getAllShippingOrder);
  yield takeLatest(Actions.updateShippingOrderRequest, updateShippingOrder);
  yield takeLatest(Actions.updateShippingIdOrderRequest, updateShippingIdOrder);
  yield takeLatest(Actions.printedOrderRequest, printedOrder);
  yield takeLatest(Actions.updateStatusOrderRequest, updateStatusOrder);
  yield takeLatest(Actions.updateWarehouseOrderRequest, updateWarehouseOrder);
  yield takeLatest(Actions.getAllWareHouseOrderRequest, getAllWarehouse);
  yield takeLatest(Actions.getAllRegionGroupOrderRequest, getAllRegionGroup);
  yield takeLatest(Actions.getOrderLateRequest, getOrderLate);
  yield takeLatest(Actions.updateStatusMultiRequest, updateStatusMulti);
  yield takeLatest(Actions.getAllOrderInChartRevenueRequest, getAllOrdersInChartRevenue);
  yield takeLatest(Actions.getAllOrderInChartGrowRequest, getAllOrdersInChartGrow);
  yield takeLatest(Actions.updateContemporaryExpenseRequest, updateContemporaryExpense);
  yield takeLatest(Actions.getAllOrderFromAppRequest, getAllOrderFromApp);
  yield takeLatest(Actions.approveOrderFromAppRequest, approveOrderFromApp);
  yield takeLatest(Actions.refuseOrderFromAppRequest, refuseOrderFromApp);
  yield takeLatest(Actions.rollBackOrderRequest, rollBackOrder);
  yield takeLatest(Actions.printOrderAndRedirectRequest, printOrderAndRedirect);
  yield takeLatest(Actions.updatePaymentOrderAppRequest, updatePaymentOrderApp);
  yield takeLatest(Actions.getLogOrderRequest, getLogOrder);
  yield takeLatest(Actions.exchangeVoucherOrderAppRequest, exchangeOrderFromApp);
  yield takeLatest(Actions.getOrderDetailRequest, getDetailOrder);
  yield takeLatest(Actions.getOrderByQrCodeRequest, getOrderByQrCode);
  yield takeLatest(Actions.cancelOrderRequest, cancelOrder);
  yield takeLatest(Actions.refundOrderRequest, refundOrder);
  yield takeLatest(Actions.createSellingVoucherRequest, createSellingVoucher);
  yield takeLatest(Actions.createPurcharseVoucherRequest, createPurcharseVoucher);
  yield takeLatest(Actions.getListOrderStaffPackingRequest, getListOrderStaffPacking);
  yield takeLatest(Actions.getOrderByQrCodeStaffPackingRequest, getOrderByQrCodeStaffPacking);
  yield takeLatest(Actions.updateStatusOrderStaffPackingRequest, updateStatusOrderStaffPacking);
  yield takeLatest(Actions.getPriceShippingLalaRequest, getPriceShippingLala);
  yield takeLatest(Actions.createShippingLalaRequest, createShippingLala);
  yield takeLatest(Actions.getListOrderLalaAcceptedRequest, getListOrderLalaAccepted);
  yield takeLatest(Actions.createFeeSangthuyRequest, createFeeSangthuy);
  yield takeLatest(Actions.getLogLalaMoveRequest, getLogLalaMove);
  yield takeLatest(Actions.updateFeeLalaMoveRequest, updateFeeLalaMove);
  yield takeLatest(Actions.createFeeLalaManualRequest, createFeeLalaManual);
  yield takeLatest(Actions.cancelLalaMoveRequest, cancelLalaMove);
  yield takeLatest(Actions.claimPermissionRequest, claimPermission);
  yield takeLatest(Actions.acceptPermissionRequest, acceptPermission);
  yield takeLatest(Actions.updateOrderLalaRequest, updateOrderLala);
  yield takeLatest(Actions.getListOrderLalaCanChangeRequest, getListOrderLalaCanChange);
  yield takeLatest(Actions.getAllOrderWaitingRequest, getAllOrderWaitings);
  yield takeLatest(Actions.getAllOrderWaitingRequest, getAllOrderWaitings);
  yield takeLatest(Actions.getAllProductSizesOrderRequest, getAllProductSizesOrder);
  yield takeLatest(Actions.splitOrderRequest, splitOrder);
  yield takeLatest(Actions.mergeOrderRequest, mergeOrder);
  yield takeLatest(Actions.checkProductInStockRequest, checkProductInStock);
  yield takeLatest(Actions.getListOrdersAllRequest, getListOrdersAll);
  yield takeLatest(Actions.updatePriceOrderRequest, updatePriceOrder);
}
