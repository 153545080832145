// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllExportPlansRequest = createAction('GET_ALL_EXPORTPLANS_REQUEST');
export const getAllExportPlansSuccess = createAction('GET_ALL_EXPORTPLANS_SUCCESS');
export const getAllExportPlansFailure = createAction('GET_ALL_EXPORTPLANS_FAILURE');

export const createExportPlanRequest = createAction('CREATE_EXPORTPLAN_REQUEST');
export const createExportPlanSuccess = createAction('CREATE_EXPORTPLAN_SUCCESS');
export const createExportPlanFailure = createAction('CREATE_EXPORTPLAN_FAILURE');
export const resetCreateExportPlan = createAction('RESET_CREATE_EXPORTPLAN');

export const updateExportPlanRequest = createAction('UPDATE_EXPORTPLAN_REQUEST');
export const updateExportPlanSuccess = createAction('UPDATE_EXPORTPLAN_SUCCESS');
export const updateExportPlanFailure = createAction('UPDATE_EXPORTPLAN_FAILURE');
export const resetUpdateExportPlan = createAction('RESET_UPDATE_EXPORTPLAN');

export const deleteExportPlanRequest = createAction('DELETE_EXPORTPLAN_REQUEST');
export const deleteExportPlanSuccess = createAction('DELETE_EXPORTPLAN_SUCCESS');
export const deleteExportPlanFailure = createAction('DELETE_EXPORTPLAN_FAILURE');
export const resetDeleteExportPlan = createAction('RESET_DELETE_EXPORTPLAN');

export const cancelExportPlanRequest = createAction('CANCEL_EXPORTPLAN_REQUEST');
export const cancelExportPlanSuccess = createAction('CANCEL_EXPORTPLAN_SUCCESS');
export const cancelExportPlanFailure = createAction('CANCEL_EXPORTPLAN_FAILURE');
export const resetCancelExportPlan = createAction('RESET_CANCEL_EXPORTPLAN');

export const resetExportPlanState = createAction('RESET_EXPORTPLAN_STATE');
