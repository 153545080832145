// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllMarketsRequest = createAction(
  'GET_ALL_MARKETS_REQUEST',
);
export const getAllMarketsSuccess = createAction(
  'GET_ALL_MARKETS_SUCCESS',
);
export const getAllMarketsFailure = createAction(
  'GET_ALL_MARKETS_FAILURE',
);

export const createMarketsRequest = createAction(
  'CREATE_MARKETS_REQUEST',
);
export const createMarketsSuccess = createAction(
  'CREATE_MARKETS_SUCCESS',
);
export const createMarketsFailure = createAction(
  'CREATE_MARKETS_FAILURE',
);
export const resetCreateMarketsState = createAction(
  'RESET_CREATE_MARKETS_STATE',
);

export const updateMarketsRequest = createAction(
  'UPDATE_MARKETS_REQUEST',
);
export const updateMarketsSuccess = createAction(
  'UPDATE_MARKETS_SUCCESS',
);
export const updateMarketsFailure = createAction(
  'UPDATE_MARKETS_FAILURE',
);
export const resetUpdateMarketsState = createAction(
  'RESET_UPDATE_MARKETS_STATE',
);

export const deleteMarketsRequest = createAction(
  'DELETE_MARKETS_REQUEST',
);
export const deleteMarketsSuccess = createAction(
  'DELETE_MARKETS_SUCCESS',
);
export const deleteMarketsFailure = createAction(
  'DELETE_MARKETS_FAILURE',
);
export const resetDeleteMarketsState = createAction(
  'RESET_DELETE_MARKETS_STATE',
);
