/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllExportPlans({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/export-plans?${payload}`)
        : () => axiosMicro.get('/export-plans', { params: payload }),
    );
    yield put(Actions.getAllExportPlansSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllExportPlansFailure(messages));
    }
  }
}

function* createExportPlan({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/export-plans', payload));
    yield put(Actions.createExportPlanSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createExportPlanFailure(messages));
    }
  }
}

function* updateExportPlan({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/export-plans/${payload.id}`, payload.body));
    yield put(Actions.updateExportPlanSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateExportPlanFailure(messages));
    }
  }
}

function* deleteExportPlan({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/export-plans/${payload}`));
    yield put(Actions.deleteExportPlanSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteExportPlanFailure(messages));
    }
  }
}

function* cancelExportPlan({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/export-plans/${payload.id}/cancel`, payload.body));
    yield put(Actions.cancelExportPlanSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.cancelExportPlanFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllExportPlansRequest, getAllExportPlans);
  yield takeLatest(Actions.createExportPlanRequest, createExportPlan);
  yield takeLatest(Actions.updateExportPlanRequest, updateExportPlan);
  yield takeLatest(Actions.deleteExportPlanRequest, deleteExportPlan);
  yield takeLatest(Actions.cancelExportPlanRequest, cancelExportPlan);
}
