// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getListDepositOrdersRequest = createAction('GET_LIST_DEPOSIT_ORDERS_REQUEST');
export const getListDepositOrdersSuccess = createAction('GET_LIST_DEPOSIT_ORDERS_SUCCESS');
export const getListDepositOrdersFailure = createAction('GET_LIST_DEPOSIT_ORDERS_FAILURE');

export const getDepositOrderDetailRequest = createAction('GET_DEPOSIT_ORDER_DETAIL_REQUEST');
export const getDepositOrderDetailSuccess = createAction('GET_DEPOSIT_ORDER_DETAIL_SUCCESS');
export const getDepositOrderDetailFailure = createAction('GET_DEPOSIT_ORDER_DETAIL_FAILURE');

export const createOrderBookingRequest = createAction('CREATE_ORDER_BOOKING_REQUEST');
export const createOrderBookingSuccess = createAction('CREATE_ORDER_BOOKING_SUCCESS');
export const createOrderBookingFailure = createAction('CREATE_ORDER_BOOKING_FAILURE');
export const resetCreateOrderBookingState = createAction('RESET_CREATE_ORDER_BOOKING_STATE');

export const getOrderBookingHistoryRequest = createAction('GET_ORDER_BOOKING_HISTORY_REQUEST');
export const getOrderBookingHistorySuccess = createAction('GET_ORDER_BOOKING_HISTORY_SUCCESS');
export const getOrderBookingHistoryFailure = createAction('GET_ORDER_BOOKING_HISTORY_FAILURE');
export const resetGetOrderBookingHistoryState = createAction('RESET_GET_BOOKING_ORDER_HISTORY_STATE');

export const updateOrderBookingRequest = createAction('UPDATE_ORDER_BOOKING_REQUEST');
export const updateOrderBookingSuccess = createAction('UPDATE_ORDER_BOOKING_SUCCESS');
export const updateOrderBookingFailure = createAction('UPDATE_ORDER_BOOKING_FAILURE');
export const resetUpdateOrderBookingState = createAction('RESET_UPDATE_ORDER_BOOKING_STATE');

export const activeOrderBookingRequest = createAction('ACTIVE_ORDER_BOOKING_REQUEST');
export const activeOrderBookingSuccess = createAction('ACTIVE_ORDER_BOOKING_SUCCESS');
export const activeOrderBookingFailure = createAction('ACTIVE_ORDER_BOOKING_FAILURE');
export const resetActiveOrderBookingState = createAction('RESET_ACTIVE_ORDER_BOOKING_STATE');

export const doneOrderBookingRequest = createAction('DONE_ORDER_BOOKING_REQUEST');
export const doneOrderBookingSuccess = createAction('DONE_ORDER_BOOKING_SUCCESS');
export const doneOrderBookingFailure = createAction('DONE_ORDER_BOOKING_FAILURE');
export const resetDoneOrderBookingState = createAction('RESET_DONE_ORDER_BOOKING_STATE');

export const cancelOrderBookingRequest = createAction('CANCEL_ORDER_BOOKING_REQUEST');
export const cancelOrderBookingSuccess = createAction('CANCEL_ORDER_BOOKING_SUCCESS');
export const cancelOrderBookingFailure = createAction('CANCEL_ORDER_BOOKING_FAILURE');
export const resetCancelOrderBookingState = createAction('RESET_CANCEL_ORDER_BOOKING_STATE');

export const getMoneyBookingOrderRequest = createAction('GET_MONEY_BOOKING_ORDER');
export const getMoneyBookingOrderSuccess = createAction('GET_MONEY_BOOKING_SUCCESS');
export const getMoneyBookingOrderFailure = createAction('GET_MONEY_BOOKING_FAILURE');
export const resetGetMoneyBookingOrderState = createAction('RESET_GET_MONEY_BOOKING_ORDER_STATE');
