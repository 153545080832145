/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllSupervisors({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/supervisors', payload));
    yield put(Actions.getAllSupervisorsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllSupervisorsFailure(messages));
    }
  }
}

function* createSupervisors({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/supervisors', payload));
    yield put(Actions.createSupervisorsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createSupervisorsFailure(messages));
    }
  }
}

function* updateSupervisors({ payload }) {
  const { id, ...data } = payload;
  try {
    const response = yield call(() => axiosMicro.patch(`/supervisors/${id}`, data));
    yield put(Actions.updateSupervisorsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateSupervisorsFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllSupervisorsRequest, getAllSupervisors);
  yield takeLatest(Actions.createSupervisorsRequest, createSupervisors);
  yield takeLatest(Actions.updateSupervisorsRequest, updateSupervisors);
}
