/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllMarkets({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/markets', payload));
    yield put(Actions.getAllMarketsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllMarketsFailure(messages));
    }
  }
}

function* createMarkets({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/markets', payload));
    yield put(Actions.createMarketsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createMarketsFailure(messages));
    }
  }
}

function* updateMarkets({ payload }) {
  const { id, ...data } = payload;
  try {
    const response = yield call(() => axiosMicro.patch(`/markets/${id}`, data));
    yield put(Actions.updateMarketsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateMarketsFailure(messages));
    }
  }
}

function* deleteMarkets({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/markets/${payload}`));
    yield put(Actions.deleteMarketsSuccess(payload));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteMarketsFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllMarketsRequest, getAllMarkets);
  yield takeLatest(Actions.createMarketsRequest, createMarkets);
  yield takeLatest(Actions.updateMarketsRequest, updateMarkets);
  yield takeLatest(Actions.deleteMarketsRequest, deleteMarkets);
}
