// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllDriversRequest = createAction('GET_ALL_DRIVERS_REQUEST');
export const getAllDriversSuccess = createAction('GET_ALL_DRIVERS_SUCCESS');
export const getAllDriversFailure = createAction('GET_ALL_DRIVERS_FAILURE');

export const createDriverRequest = createAction('CREATE_DRIVER_REQUEST');
export const createDriverSuccess = createAction('CREATE_DRIVER_SUCCESS');
export const createDriverFailure = createAction('CREATE_DRIVER_FAILURE');
export const resetCreateDriver = createAction('RESET_CREATE_DRIVER');

export const updateDriverRequest = createAction('UPDATE_DRIVER_REQUEST');
export const updateDriverSuccess = createAction('UPDATE_DRIVER_SUCCESS');
export const updateDriverFailure = createAction('UPDATE_DRIVER_FAILURE');
export const resetUpdateDriver = createAction('RESET_UPDATE_DRIVER');

export const deleteDriverRequest = createAction('DELETE_DRIVER_REQUEST');
export const deleteDriverSuccess = createAction('DELETE_DRIVER_SUCCESS');
export const deleteDriverFailure = createAction('DELETE_DRIVER_FAILURE');
export const resetDeleteDriver = createAction('RESET_DELETE_DRIVER');

export const updateExportDriverRequest = createAction('UPDATE_EXPORT_DRIVER_REQUEST');
export const updateExportDriverSuccess = createAction('UPDATE_EXPORT_DRIVER_SUCCESS');
export const updateExportDriverFailure = createAction('UPDATE_EXPORT_DRIVER_FAILURE');
export const resetUpdateExportDriver = createAction('RESET_UPDATE_EXPORT_DRIVER');

export const getDriverExportRequest = createAction('GET_DRIVER_EXPORT_REQUEST');
export const getDriverExportSuccess = createAction('GET_DRIVER_EXPORT_SUCCESS');
export const getDriverExportFailure = createAction('GET_DRIVER_EXPORT_FAILURE');

export const getOneDriverExportRequest = createAction('GET_ONE_DRIVER_EXPORT_REQUEST');
export const getOneDriverExportSuccess = createAction('GET_ONE_DRIVER_EXPORT_SUCCESS');
export const getOneDriverExportFailure = createAction('GET_ONE_DRIVER_EXPORT_FAILURE');

export const updateDriverSignRequest = createAction('UPDATE_DRIVER_SIGN_REQUEST');
export const updateDriverSignSuccess = createAction('UPDATE_DRIVER_SIGN_SUCCESS');
export const updateDriverSignFailure = createAction('UPDATE_DRIVER_SIGN_FAILURE');
export const resetUpdateDriverSign = createAction('RESET_UPDATE_DRIVER_SIGN');

export const updateSignExportDriverRequest = createAction('UPDATE_SIGN_EXPORT_DRIVER_REQUEST');
export const updateSignExportDriverSuccess = createAction('UPDATE_SIGN_EXPORT_DRIVER_SUCCESS');
export const updateSignExportDriverFailure = createAction('UPDATE_SIGN_EXPORT_DRIVER_FAILURE');
export const resetUpdateSignExportDriver = createAction('RESET_UPDATE_SIGN_EXPORT_DRIVER');

export const resetDriverState = createAction('RESET_DRIVER_STATE');
