// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Eggs
  isGetAllEggsRequest: false,
  isGetAllEggsSuccess: false,
  isGetAllEggsFailure: false,
  allEggs: {},
  //
  isCreateEggsRequest: false,
  isCreateEggsSuccess: false,
  isCreateEggsFailure: false,
  //
  isUpdateEggsRequest: false,
  isUpdateEggsSuccess: false,
  isUpdateEggsFailure: false,
  //
  isCancelEggsRequest: false,
  isCancelEggsSuccess: false,
  isCancelEggsFailure: false,
  //
  isTankEggsRequest: false,
  isTankEggsSuccess: false,
  isTankEggsFailure: false,
  //
  isProcessEggsRequest: false,
  isProcessEggsSuccess: false,
  isProcessEggsFailure: false,
  //
  isInputEggsRequest: false,
  isInputEggsSuccess: false,
  isInputEggsFailure: false,
  //
  isRollbackEggsRequest: false,
  isRollbackEggsSuccess: false,
  isRollbackEggsFailure: false,
  //
  isCountEggsRequest: false,
  isCountEggsSuccess: false,
  isCountEggsFailure: false,
  // Egg suppliers
  isGetAllEggSuppliersRequest: false,
  isGetAllEggSuppliersSuccess: false,
  isGetAllEggSuppliersFailure: false,
  allEggSuppliers: {},
  //
  isCreateEggSuppliersRequest: false,
  isCreateEggSuppliersSuccess: false,
  isCreateEggSuppliersFailure: false,
  //
  isUpdateEggSuppliersRequest: false,
  isUpdateEggSuppliersSuccess: false,
  isUpdateEggSuppliersFailure: false,
  //
  isCreateEggSuppliersCodeRequest: false,
  isCreateEggSuppliersCodeSuccess: false,
  isCreateEggSuppliersCodeFailure: false,
  eggSupplierCodeData: null,
  // Egg tanks
  isGetAllEggTanksRequest: false,
  isGetAllEggTanksSuccess: false,
  isGetAllEggTanksFailure: false,
  allEggTanks: {},
  //
  isCreateEggTanksRequest: false,
  isCreateEggTanksSuccess: false,
  isCreateEggTanksFailure: false,
  //
  isUpdateEggTanksRequest: false,
  isUpdateEggTanksSuccess: false,
  isUpdateEggTanksFailure: false,
  //
  isProcessEggTanksRequest: false,
  isProcessEggTanksSuccess: false,
  isProcessEggTanksFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all Eggs
    [Actions.getAllEggsRequest]: (state) => ({
      ...state,
      isGetAllEggsRequest: true,
      isGetAllEggsSuccess: false,
      isGetAllEggsFailure: false,
    }),
    [Actions.getAllEggsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllEggsRequest: false,
      isGetAllEggsSuccess: true,
      isGetAllEggsFailure: false,
      allEggs: payload,
    }),
    [Actions.getAllEggsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllEggsRequest: false,
      isGetAllEggsSuccess: false,
      isGetAllEggsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Eggs
    [Actions.createEggsRequest]: (state) => ({
      ...state,
      isCreateEggsRequest: true,
      isCreateEggsSuccess: false,
      isCreateEggsFailure: false,
    }),
    [Actions.createEggsSuccess]: (state, { payload }) => {
      const getAllEggsStateTmp = handleUpdateDataList(state.allEggs, payload.data, 'create');

      return ({
        ...state,
        isCreateEggsRequest: false,
        isCreateEggsSuccess: true,
        isCreateEggsFailure: false,
        allEggs: { ...getAllEggsStateTmp },
      });
    },
    [Actions.createEggsFailure]: (state, { payload }) => ({
      ...state,
      isCreateEggsRequest: false,
      isCreateEggsSuccess: false,
      isCreateEggsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateEggsState]: (state) => ({
      ...state,
      isCreateEggsRequest: false,
      isCreateEggsSuccess: false,
      isCreateEggsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update Eggs
    [Actions.updateEggsRequest]: (state) => ({
      ...state,
      isUpdateEggsRequest: true,
      isUpdateEggsSuccess: false,
      isUpdateEggsFailure: false,
    }),
    [Actions.updateEggsSuccess]: (state, { payload }) => {
      const getAllEggsStateTmp = handleUpdateDataList(state.allEggs, payload.data, 'update');

      return ({
        ...state,
        isUpdateEggsRequest: false,
        isUpdateEggsSuccess: true,
        isUpdateEggsFailure: false,
        allEggs: { ...getAllEggsStateTmp },
      });
    },
    [Actions.updateEggsFailure]: (state, { payload }) => ({
      ...state,
      isUpdateEggsRequest: false,
      isUpdateEggsSuccess: false,
      isUpdateEggsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateEggsState]: (state) => ({
      ...state,
      isUpdateEggsRequest: false,
      isUpdateEggsSuccess: false,
      isUpdateEggsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region:  Cancel Eggs
    [Actions.cancelEggsRequest]: (state) => ({
      ...state,
      isCancelEggsRequest: true,
      isCancelEggsSuccess: false,
      isCancelEggsFailure: false,
    }),
    [Actions.cancelEggsSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateEggsRequest: false,
      isUpdateEggsSuccess: true,
      isUpdateEggsFailure: false,
      errorMessages: payload,
    }),
    [Actions.cancelEggsFailure]: (state, { payload }) => ({
      ...state,
      isCancelEggsRequest: false,
      isCancelEggsSuccess: false,
      isCancelEggsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelEggsState]: (state) => ({
      ...state,
      isCancelEggsRequest: false,
      isCancelEggsSuccess: false,
      isCancelEggsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region: Tank Eggs
    [Actions.tankEggsRequest]: (state) => ({
      ...state,
      isTankEggsRequest: true,
      isTankEggsSuccess: false,
      isTankEggsFailure: false,
    }),
    [Actions.tankEggsSuccess]: (state, { payload }) => ({
      ...state,
      isTankEggsRequest: false,
      isTankEggsSuccess: true,
      isTankEggsFailure: false,
      errorMessages: payload,
    }),
    [Actions.tankEggsFailure]: (state, { payload }) => ({
      ...state,
      isTankEggsRequest: false,
      isTankEggsSuccess: false,
      isTankEggsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetTankEggsState]: (state) => ({
      ...state,
      isTankEggsRequest: false,
      isTankEggsSuccess: false,
      isTankEggsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region: Process Eggs
    [Actions.processEggsRequest]: (state) => ({
      ...state,
      isProcessEggsRequest: true,
      isProcessEggsSuccess: false,
      isProcessEggsFailure: false,
    }),
    [Actions.processEggsSuccess]: (state, { payload }) => ({
      ...state,
      isProcessEggsRequest: false,
      isProcessEggsSuccess: true,
      isProcessEggsFailure: false,
      errorMessages: payload,
    }),
    [Actions.processEggsFailure]: (state, { payload }) => ({
      ...state,
      isProcessEggsRequest: false,
      isProcessEggsSuccess: false,
      isProcessEggsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetProcessEggsState]: (state) => ({
      ...state,
      isProcessEggsRequest: false,
      isProcessEggsSuccess: false,
      isProcessEggsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region: Input Eggs
    [Actions.inputEggsRequest]: (state) => ({
      ...state,
      isInputEggsRequest: true,
      isInputEggsSuccess: false,
      isInputEggsFailure: false,
    }),
    [Actions.inputEggsSuccess]: (state, { payload }) => ({
      ...state,
      isInputEggsRequest: false,
      isInputEggsSuccess: true,
      isInputEggsFailure: false,
      errorMessages: payload,
    }),
    [Actions.inputEggsFailure]: (state, { payload }) => ({
      ...state,
      isInputEggsRequest: false,
      isInputEggsSuccess: false,
      isInputEggsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetInputEggsState]: (state) => ({
      ...state,
      isInputEggsRequest: false,
      isInputEggsSuccess: false,
      isInputEggsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region: Roll back Eggs
    [Actions.rollbackEggsRequest]: (state) => ({
      ...state,
      isRollbackEggsRequest: true,
      isRollbackEggsSuccess: false,
      isRollbackEggsFailure: false,
    }),
    [Actions.rollbackEggsSuccess]: (state, { payload }) => ({
      ...state,
      isRollbackEggsRequest: false,
      isRollbackEggsSuccess: true,
      isRollbackEggsFailure: false,
      errorMessages: payload,
    }),
    [Actions.rollbackEggsFailure]: (state, { payload }) => ({
      ...state,
      isRollbackEggsRequest: false,
      isRollbackEggsSuccess: false,
      isRollbackEggsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRollbackEggsState]: (state) => ({
      ...state,
      isRollbackEggsRequest: false,
      isRollbackEggsSuccess: false,
      isRollbackEggsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region: Count Eggs
    [Actions.countEggsRequest]: (state) => ({
      ...state,
      isCountEggsRequest: true,
      isCountEggsSuccess: false,
      isCountEggsFailure: false,
    }),
    [Actions.countEggsSuccess]: (state, { payload }) => ({
      ...state,
      isCountEggsRequest: false,
      isCountEggsSuccess: true,
      isCountEggsFailure: false,
      errorMessages: payload,
    }),
    [Actions.countEggsFailure]: (state, { payload }) => ({
      ...state,
      isCountEggsRequest: false,
      isCountEggsSuccess: false,
      isCountEggsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCountEggsState]: (state) => ({
      ...state,
      isCountEggsRequest: false,
      isCountEggsSuccess: false,
      isCountEggsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get all EggSuppliers
    [Actions.getAllEggSuppliersRequest]: (state) => ({
      ...state,
      isGetAllEggSuppliersRequest: true,
      isGetAllEggSuppliersSuccess: false,
      isGetAllEggSuppliersFailure: false,
    }),
    [Actions.getAllEggSuppliersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllEggSuppliersRequest: false,
      isGetAllEggSuppliersSuccess: true,
      isGetAllEggSuppliersFailure: false,
      allEggSuppliers: payload,
    }),
    [Actions.getAllEggSuppliersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllEggSuppliersRequest: false,
      isGetAllEggSuppliersSuccess: false,
      isGetAllEggSuppliersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create EggSuppliers
    [Actions.createEggSuppliersRequest]: (state) => ({
      ...state,
      isCreateEggSuppliersRequest: true,
      isCreateEggSuppliersSuccess: false,
      isCreateEggSuppliersFailure: false,
    }),
    [Actions.createEggSuppliersSuccess]: (state, { payload }) => {
      const getAllEggSuppliersStateTmp = handleUpdateDataList(state.allEggSuppliers, payload.data, 'create');

      return ({
        ...state,
        isCreateEggSuppliersRequest: false,
        isCreateEggSuppliersSuccess: true,
        isCreateEggSuppliersFailure: false,
        allEggSuppliers: { ...getAllEggSuppliersStateTmp },
      });
    },
    [Actions.createEggSuppliersFailure]: (state, { payload }) => ({
      ...state,
      isCreateEggSuppliersRequest: false,
      isCreateEggSuppliersSuccess: false,
      isCreateEggSuppliersFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateEggSuppliersState]: (state) => ({
      ...state,
      isCreateEggSuppliersRequest: false,
      isCreateEggSuppliersSuccess: false,
      isCreateEggSuppliersFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update EggSuppliers
    [Actions.updateEggSuppliersRequest]: (state) => ({
      ...state,
      isUpdateEggSuppliersRequest: true,
      isUpdateEggSuppliersSuccess: false,
      isUpdateEggSuppliersFailure: false,
    }),
    [Actions.updateEggSuppliersSuccess]: (state, { payload }) => {
      const getAllEggSuppliersStateTmp = handleUpdateDataList(state.allEggSuppliers, payload.data, 'update');

      return ({
        ...state,
        isUpdateEggSuppliersRequest: false,
        isUpdateEggSuppliersSuccess: true,
        isUpdateEggSuppliersFailure: false,
        allEggSuppliers: { ...getAllEggSuppliersStateTmp },
      });
    },
    [Actions.updateEggSuppliersFailure]: (state, { payload }) => ({
      ...state,
      isUpdateEggSuppliersRequest: false,
      isUpdateEggSuppliersSuccess: false,
      isUpdateEggSuppliersFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateEggSuppliersState]: (state) => ({
      ...state,
      isUpdateEggSuppliersRequest: false,
      isUpdateEggSuppliersSuccess: false,
      isUpdateEggSuppliersFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Create EggSuppliers Code
    [Actions.createEggSuppliersCodeRequest]: (state) => ({
      ...state,
      isCreateEggSuppliersCodeRequest: true,
      isCreateEggSuppliersCodeSuccess: false,
      isCreateEggSuppliersCodeFailure: false,
    }),
    [Actions.createEggSuppliersCodeSuccess]: (state, { payload }) => ({
      ...state,
      isCreateEggSuppliersCodeRequest: false,
      isCreateEggSuppliersCodeSuccess: true,
      isCreateEggSuppliersCodeFailure: false,
      eggSupplierCodeData: payload,
    }),
    [Actions.createEggSuppliersCodeFailure]: (state, { payload }) => ({
      ...state,
      isCreateEggSuppliersCodeRequest: false,
      isCreateEggSuppliersCodeSuccess: false,
      isCreateEggSuppliersCodeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateEggSuppliersCodeState]: (state) => ({
      ...state,
      isCreateEggSuppliersCodeRequest: false,
      isCreateEggSuppliersCodeSuccess: false,
      isCreateEggSuppliersCodeFailure: false,
      eggSupplierCodeData: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Get all EggTanks
    [Actions.getAllEggTanksRequest]: (state) => ({
      ...state,
      isGetAllEggTanksRequest: true,
      isGetAllEggTanksSuccess: false,
      isGetAllEggTanksFailure: false,
    }),
    [Actions.getAllEggTanksSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllEggTanksRequest: false,
      isGetAllEggTanksSuccess: true,
      isGetAllEggTanksFailure: false,
      allEggTanks: payload,
    }),
    [Actions.getAllEggTanksFailure]: (state, { payload }) => ({
      ...state,
      isGetAllEggTanksRequest: false,
      isGetAllEggTanksSuccess: false,
      isGetAllEggTanksFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create EggTanks
    [Actions.createEggTanksRequest]: (state) => ({
      ...state,
      isCreateEggTanksRequest: true,
      isCreateEggTanksSuccess: false,
      isCreateEggTanksFailure: false,
    }),
    [Actions.createEggTanksSuccess]: (state, { payload }) => {
      const getAllEggTanksStateTmp = handleUpdateDataList(state.allEggTanks, payload.data, 'create');

      return ({
        ...state,
        isCreateEggTanksRequest: false,
        isCreateEggTanksSuccess: true,
        isCreateEggTanksFailure: false,
        allEggTanks: { ...getAllEggTanksStateTmp },
      });
    },
    [Actions.createEggTanksFailure]: (state, { payload }) => ({
      ...state,
      isCreateEggTanksRequest: false,
      isCreateEggTanksSuccess: false,
      isCreateEggTanksFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateEggTanksState]: (state) => ({
      ...state,
      isCreateEggTanksRequest: false,
      isCreateEggTanksSuccess: false,
      isCreateEggTanksFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update EggTanks
    [Actions.updateEggTanksRequest]: (state) => ({
      ...state,
      isUpdateEggTanksRequest: true,
      isUpdateEggTanksSuccess: false,
      isUpdateEggTanksFailure: false,
    }),
    [Actions.updateEggTanksSuccess]: (state, { payload }) => {
      const getAllEggTanksStateTmp = handleUpdateDataList(state.allEggTanks, payload.data, 'update');

      return ({
        ...state,
        isUpdateEggTanksRequest: false,
        isUpdateEggTanksSuccess: true,
        isUpdateEggTanksFailure: false,
        allEggTanks: { ...getAllEggTanksStateTmp },
      });
    },
    [Actions.updateEggTanksFailure]: (state, { payload }) => ({
      ...state,
      isUpdateEggTanksRequest: false,
      isUpdateEggTanksSuccess: false,
      isUpdateEggTanksFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateEggTanksState]: (state) => ({
      ...state,
      isUpdateEggTanksRequest: false,
      isUpdateEggTanksSuccess: false,
      isUpdateEggTanksFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Process EggTanks
    [Actions.processEggTanksRequest]: (state) => ({
      ...state,
      isProcessEggTanksRequest: true,
      isProcessEggTanksSuccess: false,
      isProcessEggTanksFailure: false,
    }),
    [Actions.processEggTanksSuccess]: (state, { payload }) => ({
      ...state,
      isProcessEggTanksRequest: false,
      isProcessEggTanksSuccess: true,
      isProcessEggTanksFailure: false,
      errorMessages: payload,
    }),
    [Actions.processEggTanksFailure]: (state, { payload }) => ({
      ...state,
      isProcessEggTanksRequest: false,
      isProcessEggTanksSuccess: false,
      isProcessEggTanksFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetProcessEggTanksState]: (state) => ({
      ...state,
      isProcessEggTanksRequest: false,
      isProcessEggTanksSuccess: false,
      isProcessEggTanksFailure: false,
      errorMessages: [],
    }),
  },
  initialState,
);

export default reducer;
