// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All Market
  isFirstGetAllMarkets: false,
  isGetAllMarketsRequest: false,
  isGetAllMarketsSuccess: false,
  isGetAllMarketsFailure: false,
  getAllMarketsState: {},
  // Create Market
  isCreateMarketRequest: false,
  isCreateMarketSuccess: false,
  isCreateMarketFailure: false,
  // Update Market
  isUpdateMarketRequest: false,
  isUpdateMarketSuccess: false,
  isUpdateMarketFailure: false,
  // Delete Market
  isDeleteMarketRequest: false,
  isDeleteMarketSuccess: false,
  isDeleteMarketFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Market
    [Actions.getAllMarketsRequest]: (state) => ({
      ...state,
      isGetAllMarketsRequest: true,
      isGetAllMarketsSuccess: false,
      isGetAllMarketsFailure: false,
    }),
    [Actions.getAllMarketsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllMarkets: true,
      isGetAllMarketsRequest: false,
      isGetAllMarketsSuccess: true,
      isGetAllMarketsFailure: false,
      getAllMarketsState: payload,
    }),
    [Actions.getAllMarketsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllMarketsRequest: false,
      isGetAllMarketsSuccess: false,
      isGetAllMarketsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Market
    [Actions.createMarketRequest]: (state) => ({
      ...state,
      isCreateMarketRequest: true,
      isCreateMarketSuccess: false,
      isCreateMarketFailure: false,
    }),
    [Actions.createMarketSuccess]: (state, { payload }) => {
      const getAllMarketsStateTmp = handleUpdateDataList(state.getAllMarketsState, payload.data, 'create');

      return ({
        ...state,
        isCreateMarketRequest: false,
        isCreateMarketSuccess: true,
        isCreateMarketFailure: false,
        getAllMarketsState: { ...getAllMarketsStateTmp },
      });
    },
    [Actions.createMarketFailure]: (state, { payload }) => ({
      ...state,
      isCreateMarketRequest: false,
      isCreateMarketSuccess: false,
      isCreateMarketFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateMarket]: (state) => ({
      ...state,
      isCreateMarketRequest: false,
      isCreateMarketSuccess: false,
      isCreateMarketFailure: false,
    }),
    // #endregion
    // #region : Update Market
    [Actions.updateMarketRequest]: (state) => ({
      ...state,
      isUpdateMarketRequest: true,
      isUpdateMarketSuccess: false,
      isUpdateMarketFailure: false,
    }),
    [Actions.updateMarketSuccess]: (state, { payload }) => {
      const getAllMarketsStateTmp = handleUpdateDataList(state.getAllMarketsState, payload.data, 'update');

      return ({
        ...state,
        isUpdateMarketRequest: false,
        isUpdateMarketSuccess: true,
        isUpdateMarketFailure: false,
        getAllMarketsState: { ...getAllMarketsStateTmp },
      });
    },
    [Actions.updateMarketFailure]: (state, { payload }) => ({
      ...state,
      isUpdateMarketRequest: false,
      isUpdateMarketSuccess: false,
      isUpdateMarketFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateMarket]: (state) => ({
      ...state,
      isUpdateMarketRequest: false,
      isUpdateMarketSuccess: false,
      isUpdateMarketFailure: false,
    }),
    // #endregion
    // #region : Delete Market
    [Actions.deleteMarketRequest]: (state) => ({
      ...state,
      isDeleteMarketRequest: true,
      isDeleteMarketSuccess: false,
      isDeleteMarketFailure: false,
    }),
    [Actions.deleteMarketSuccess]: (state, { payload }) => {
      const getAllMarketsStateTmp = handleUpdateDataList(state.getAllMarketsState, payload, 'delete');

      return ({
        ...state,
        isDeleteMarketRequest: false,
        isDeleteMarketSuccess: true,
        isDeleteMarketFailure: false,
        getAllMarketsState: { ...getAllMarketsStateTmp },
      });
    },
    [Actions.deleteMarketFailure]: (state, { payload }) => ({
      ...state,
      isDeleteMarketRequest: false,
      isDeleteMarketSuccess: false,
      isDeleteMarketFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteMarket]: (state) => ({
      ...state,
      isDeleteMarketRequest: false,
      isDeleteMarketSuccess: false,
      isDeleteMarketFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetMarketState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
