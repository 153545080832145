import { all } from 'redux-saga/effects';

import auth from './auth/saga';
import employee from './employee/saga';
import permissions from './permissions/saga';
import roles from './roles/saga';
import user from './user/saga';
import product from './product/saga';
import customer from './customer/saga';
import order from './order/saga';
import regionGroup from './regionGroup/saga';
import common from './common/saga';
import productPrice from './productPrice/saga';
import calendar from './calendar/saga';
import scheduleCalendar from './scheduleCalendar/saga';
import scheduleBookCalendar from './scheduleBookCalendar/saga';
import report from './report/saga';
import config from './config/saga';
import region from './region/saga';
import chat from './chat/saga';
import chart from './chart/saga';
import work from './work/saga';
import workTracking from './workTracking/saga';
import productBag from './productBag/saga';
import productSize from './productSize/saga';
import productGrade from './productGrade/saga';
import cart from './cart/saga';
import warehouse from './warehouse/saga';
import customerBranch from './customerBranch/saga';
import exportWarehouse from './exportWarehouse/saga';
import batch from './batch/saga';
import exportProduct from './exportProduct/saga';
import refund from './refund/saga';
import shipping from './shipping/saga';
import importWarehouse from './importWarehouse/saga';
import exchangeWarehouse from './exchangeWarehouse/saga';
import driver from './driver/saga';
import resize from './resize/saga';
import label from './label/saga';
import lateReview from './lateReview/saga';
import publicOrder from './publicOrder/saga';
import publicRefund from './publicRefund/saga';
import debt from './debt/saga';
import orderBooking from './order-booking/saga';
import trackingPrices from './trackingPrices/saga';
import purchase from './purchase/saga';
import exportPlan from './exportPlan/saga';
import customerExport from './customerExport/saga';
import market from './market/saga';
import supervisor from './supervisor/saga';

export default function* rootSaga() {
  yield all([
    auth(),
    employee(),
    user(),
    roles(),
    permissions(),
    product(),
    customer(),
    order(),
    orderBooking(),
    regionGroup(),
    common(),
    productPrice(),
    calendar(),
    scheduleCalendar(),
    scheduleBookCalendar(),
    report(),
    config(),
    region(),
    chat(),
    chart(),
    work(),
    workTracking(),
    productBag(),
    productSize(),
    productGrade(),
    cart(),
    warehouse(),
    customerBranch(),
    exportWarehouse(),
    batch(),
    exportProduct(),
    refund(),
    shipping(),
    importWarehouse(),
    exchangeWarehouse(),
    driver(),
    resize(),
    label(),
    lateReview(),
    publicOrder(),
    publicRefund(),
    debt(),
    trackingPrices(),
    exportPlan(),
    customerExport(),
    purchase(),
    market(),
    supervisor(),
  ]);
}
