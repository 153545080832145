// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All CustomerExport
  isFirstGetAllCustomerExports: false,
  isGetAllCustomerExportsRequest: false,
  isGetAllCustomerExportsSuccess: false,
  isGetAllCustomerExportsFailure: false,
  getAllCustomerExportsState: {},
  // Create CustomerExport
  isCreateCustomerExportRequest: false,
  isCreateCustomerExportSuccess: false,
  isCreateCustomerExportFailure: false,
  // Update CustomerExport
  isUpdateCustomerExportRequest: false,
  isUpdateCustomerExportSuccess: false,
  isUpdateCustomerExportFailure: false,
  // Delete CustomerExport
  isDeleteCustomerExportRequest: false,
  isDeleteCustomerExportSuccess: false,
  isDeleteCustomerExportFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All CustomerExport
    [Actions.getAllCustomerExportsRequest]: (state) => ({
      ...state,
      isGetAllCustomerExportsRequest: true,
      isGetAllCustomerExportsSuccess: false,
      isGetAllCustomerExportsFailure: false,
    }),
    [Actions.getAllCustomerExportsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllCustomerExports: true,
      isGetAllCustomerExportsRequest: false,
      isGetAllCustomerExportsSuccess: true,
      isGetAllCustomerExportsFailure: false,
      getAllCustomerExportsState: payload,
    }),
    [Actions.getAllCustomerExportsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerExportsRequest: false,
      isGetAllCustomerExportsSuccess: false,
      isGetAllCustomerExportsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create CustomerExport
    [Actions.createCustomerExportRequest]: (state) => ({
      ...state,
      isCreateCustomerExportRequest: true,
      isCreateCustomerExportSuccess: false,
      isCreateCustomerExportFailure: false,
    }),
    [Actions.createCustomerExportSuccess]: (state, { payload }) => {
      const getAllCustomerExportsStateTmp = handleUpdateDataList(state.getAllCustomerExportsState, payload.data, 'create');

      return ({
        ...state,
        isCreateCustomerExportRequest: false,
        isCreateCustomerExportSuccess: true,
        isCreateCustomerExportFailure: false,
        getAllCustomerExportsState: { ...getAllCustomerExportsStateTmp },
      });
    },
    [Actions.createCustomerExportFailure]: (state, { payload }) => ({
      ...state,
      isCreateCustomerExportRequest: false,
      isCreateCustomerExportSuccess: false,
      isCreateCustomerExportFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateCustomerExport]: (state) => ({
      ...state,
      isCreateCustomerExportRequest: false,
      isCreateCustomerExportSuccess: false,
      isCreateCustomerExportFailure: false,
    }),
    // #endregion
    // #region : Update CustomerExport
    [Actions.updateCustomerExportRequest]: (state) => ({
      ...state,
      isUpdateCustomerExportRequest: true,
      isUpdateCustomerExportSuccess: false,
      isUpdateCustomerExportFailure: false,
    }),
    [Actions.updateCustomerExportSuccess]: (state, { payload }) => {
      const getAllCustomerExportsStateTmp = handleUpdateDataList(state.getAllCustomerExportsState, payload.data, 'update');

      return ({
        ...state,
        isUpdateCustomerExportRequest: false,
        isUpdateCustomerExportSuccess: true,
        isUpdateCustomerExportFailure: false,
        getAllCustomerExportsState: { ...getAllCustomerExportsStateTmp },
      });
    },
    [Actions.updateCustomerExportFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCustomerExportRequest: false,
      isUpdateCustomerExportSuccess: false,
      isUpdateCustomerExportFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateCustomerExport]: (state) => ({
      ...state,
      isUpdateCustomerExportRequest: false,
      isUpdateCustomerExportSuccess: false,
      isUpdateCustomerExportFailure: false,
    }),
    // #endregion
    // #region : Delete CustomerExport
    [Actions.deleteCustomerExportRequest]: (state) => ({
      ...state,
      isDeleteCustomerExportRequest: true,
      isDeleteCustomerExportSuccess: false,
      isDeleteCustomerExportFailure: false,
    }),
    [Actions.deleteCustomerExportSuccess]: (state, { payload }) => {
      const getAllCustomerExportsStateTmp = handleUpdateDataList(state.getAllCustomerExportsState, payload, 'delete');

      return ({
        ...state,
        isDeleteCustomerExportRequest: false,
        isDeleteCustomerExportSuccess: true,
        isDeleteCustomerExportFailure: false,
        getAllCustomerExportsState: { ...getAllCustomerExportsStateTmp },
      });
    },
    [Actions.deleteCustomerExportFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCustomerExportRequest: false,
      isDeleteCustomerExportSuccess: false,
      isDeleteCustomerExportFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteCustomerExport]: (state) => ({
      ...state,
      isDeleteCustomerExportRequest: false,
      isDeleteCustomerExportSuccess: false,
      isDeleteCustomerExportFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetCustomerExportState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
