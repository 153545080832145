/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllCustomerExports({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/customer-exports?${payload}`)
        : () => axiosMicro.get('/customer-exports', { params: payload }),
    );
    yield put(Actions.getAllCustomerExportsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllCustomerExportsFailure(messages));
    }
  }
}

function* createCustomerExport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/customer-exports', payload));
    yield put(Actions.createCustomerExportSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createCustomerExportFailure(messages));
    }
  }
}

function* updateCustomerExport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/customer-exports/${payload.id}`, payload.body));
    yield put(Actions.updateCustomerExportSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateCustomerExportFailure(messages));
    }
  }
}

function* deleteCustomerExport({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/customer-exports/${payload}`));
    yield put(Actions.deleteCustomerExportSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteCustomerExportFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllCustomerExportsRequest, getAllCustomerExports);
  yield takeLatest(Actions.createCustomerExportRequest, createCustomerExport);
  yield takeLatest(Actions.updateCustomerExportRequest, updateCustomerExport);
  yield takeLatest(Actions.deleteCustomerExportRequest, deleteCustomerExport);
}
