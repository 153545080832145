// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllMarketsRequest = createAction('GET_ALL_MARKETS_REQUEST_1');
export const getAllMarketsSuccess = createAction('GET_ALL_MARKETS_SUCCESS_1');
export const getAllMarketsFailure = createAction('GET_ALL_MARKETS_FAILURE_1');

export const createMarketRequest = createAction('CREATE_MARKET_REQUEST');
export const createMarketSuccess = createAction('CREATE_MARKET_SUCCESS');
export const createMarketFailure = createAction('CREATE_MARKET_FAILURE');
export const resetCreateMarket = createAction('RESET_CREATE_MARKET');

export const updateMarketRequest = createAction('UPDATE_MARKET_REQUEST');
export const updateMarketSuccess = createAction('UPDATE_MARKET_SUCCESS');
export const updateMarketFailure = createAction('UPDATE_MARKET_FAILURE');
export const resetUpdateMarket = createAction('RESET_UPDATE_MARKET');

export const deleteMarketRequest = createAction('DELETE_MARKET_REQUEST');
export const deleteMarketSuccess = createAction('DELETE_MARKET_SUCCESS');
export const deleteMarketFailure = createAction('DELETE_MARKET_FAILURE');
export const resetDeleteMarket = createAction('RESET_DELETE_MARKET');

export const resetMarketState = createAction('RESET_MARKET_STATE');
