// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All report
  isFirstGetAllreports: false,
  isGetAllreportsRequest: false,
  isGetAllreportsSuccess: false,
  isGetAllreportsFailure: false,
  getAllreportsState: {},
  // Create report
  isCreateReportRequest: false,
  isCreateReportSuccess: false,
  isCreateReportFailure: false,
  // Update report
  isUpdateReportRequest: false,
  isUpdateReportSuccess: false,
  isUpdateReportFailure: false,
  // Delete report
  isDeleteReportRequest: false,
  isDeleteReportSuccess: false,
  isDeleteReportFailure: false,
  // Get report warehouse detail
  isGetReportWarehouseDetailRequest: false,
  isGetReportWarehouseDetailSuccess: false,
  isGetReportWarehouseDetailFailure: false,
  getReportWarehouseDetailState: {},

  // Export excel get report warehouse detail
  isExportExcelGetReportWarehouseDetailRequest: false,
  isExportExcelGetReportWarehouseDetailSuccess: false,
  isExportExcelGetReportWarehouseDetailFailure: false,
  exportExcelGetReportWarehouseDetailState: {},

  //  Get report warehouse general
  isGetReportWarehouseGeneralRequest: false,
  isGetReportWarehouseGeneralSuccess: false,
  isGetReportWarehouseGeneralFailure: false,
  getReportWarehouseGeneralState: {},

  // Export report warehouse general
  isExportReportWarehouseGeneralRequest: false,
  isExportReportWarehouseGeneralSuccess: false,
  isExportReportWarehouseGeneralFailure: false,
  exportReportWarehouseGeneralState: {},

  // Get report warehouse product
  isGetReportWarehouseProductRequest: false,
  isGetReportWarehouseProductSuccess: false,
  isGetReportWarehouseProductFailure: false,
  getReportWarehouseProductState: {},

  // Export excel get report warehouse detail
  isExportExcelGetReportWarehouseProductRequest: false,
  isExportExcelGetReportWarehouseProductSuccess: false,
  isExportExcelGetReportWarehouseProductFailure: false,
  exportExcelGetReportWarehouseProductState: {},

  // Get List customer report
  isGetListCustomerReportRequest: false,
  isGetListCustomerReportSuccess: false,
  isGetListCustomerReportFailure: false,
  getListCustomerReportState: {},

  // Get list customer report dup
  isGetListCustomerReportDupRequest: false,
  isGetListCustomerReportDupSuccess: false,
  isGetListCustomerReportDupFailure: false,
  getListCustomerReportDupState: {},

  // Get report customer detail
  isGetReportCustomerDetailRequest: false,
  isGetReportCustomerDetailSuccess: false,
  isGetReportCustomerDetailFailure: false,
  getReportCustomerDetailState: {},

  // Export report customer detail
  isExportReportCustomerDetailRequest: false,
  isExportReportCustomerDetailSuccess: false,
  isExportReportCustomerDetailFailure: false,
  exportReportCustomerDetailState: {},

  // Get report customer export
  isGetReportCustomerExportRequest: false,
  isGetReportCustomerExportSuccess: false,
  isGetReportCustomerExportFailure: false,
  getReportCustomerExportState: {},

  // Export report customer export
  isExportReportCustomerExportRequest: false,
  isExportReportCustomerExportSuccess: false,
  isExportReportCustomerExportFailure: false,
  exportReportCustomerExportState: {},

  // Get report process details
  isGetReportProcessDetailRequest: false,
  isGetReportProcessDetailSuccess: false,
  isGetReportProcessDetailFailure: false,
  getReportProcessDetailState: {},

  // Export report process details
  isExportReportProcessDetailRequest: false,
  isExportReportProcessDetailSuccess: false,
  isExportReportProcessDetailFailure: false,
  exportReportProcessDetailState: {},

  // Get report process total
  isGetReportProcessTotalRequest: false,
  isGetReportProcessTotalSuccess: false,
  isGetReportProcessTotalFailure: false,
  getReportProcessTotalState: {},

  // Export report process total
  isExportReportProcessTotalRequest: false,
  isExportReportProcessTotalSuccess: false,
  isExportReportProcessTotalFailure: false,
  exportReportProcessTotalState: {},

  // Get report export day
  isGetReportExportDayRequest: false,
  isGetReportExportDaySuccess: false,
  isGetReportExportDayFailure: false,
  getReportExportDayState: {},

  // Export report export day
  isExportReportExportDayRequest: false,
  isExportReportExportDaySuccess: false,
  isExportReportExportDayFailure: false,
  exportReportExportDayState: {},

  // Save report export day
  isSaveReportProcessDetailRequest: false,
  isSaveReportProcessDetailSuccess: false,
  isSaveReportProcessDetailFailure: false,

  // Get report export date
  isGetReportExportDateRequest: false,
  isGetReportExportDateSuccess: false,
  isGetReportExportDateFailure: false,
  getReportExportDateState: {},

  // Get report export order date
  isGetReportExportOrderDateRequest: false,
  isGetReportExportOrderDateSuccess: false,
  isGetReportExportOrderDateFailure: false,
  getReportExportOrderDateState: {},

  // Export report export date
  isExportReportExportDateRequest: false,
  isExportReportExportDateSuccess: false,
  isExportReportExportDateFailure: false,
  exportReportExportDateState: {},

  // Export report export order date
  isExportReportExportOrderDateRequest: false,
  isExportReportExportOrderDateSuccess: false,
  isExportReportExportOrderDateFailure: false,
  exportReportExportOrderDateState: {},

  // Get report export date
  isGetReportSellDayRequest: false,
  isGetReportSellDaySuccess: false,
  isGetReportSellDayFailure: false,
  getReportSellDayState: {},

  // Export report export date
  isExportReportSellDayRequest: false,
  isExportReportSellDaySuccess: false,
  isExportReportSellDayFailure: false,
  exportReportSellDayState: {},

  // Get report export customer warehouse
  isGetReportCustomerExportWarehouseRequest: false,
  isGetReportCustomerExportWarehouseSuccess: false,
  isGetReportCustomerExportWarehouseFailure: false,
  getReportCustomerExportWarehouseState: {},

  // Export report export customer warehouse
  isExportReportCustomerExportWarehouseRequest: false,
  isExportReportCustomerExportWarehouseSuccess: false,
  isExportReportCustomerExportWarehouseFailure: false,
  exportReportCustomerExportWarehouseState: {},

  // get report export plan
  isGetReportExportPlanRequest: false,
  isGetReportExportPlanSuccess: false,
  isGetReportExportPlanFailure: false,
  getReportExportPlanState: {},

  // export report export plan
  isExportReportExportPlanRequest: false,
  isExportReportExportPlanSuccess: false,
  isExportReportExportPlanFailure: false,
  exportReportExportPlanState: {},

  // get report debt money
  isGetReportDebtMoneyRequest: false,
  isGetReportDebtMoneySuccess: false,
  isGetReportDebtMoneyFailure: false,
  getReportDebtMoneyState: {},

  // export report debt money
  isExportReportDebtMoneyRequest: false,
  isExportReportDebtMoneySuccess: false,
  isExportReportDebtMoneyFailure: false,
  exportReportDebtMoneyState: {},

  // get report order booking
  isGetReportOrderBookingRequest: false,
  isGetReportOrderBookingSuccess: false,
  isGetReportOrderBookingFailure: false,
  getReportOrderBookingState: {},

  // export report order booking
  isExportReportOrderBookingRequest: false,
  isExportReportOrderBookingSuccess: false,
  isExportReportOrderBookingFailure: false,
  exportReportOrderBookingState: {},

  // get report egg
  isGetReportEggRequest: false,
  isGetReportEggSuccess: false,
  isGetReportEggFailure: false,
  getReportEggState: {},

  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All report
    [Actions.getAllreportsRequest]: (state) => ({
      ...state,
      isGetAllreportsRequest: true,
      isGetAllreportsSuccess: false,
      isGetAllreportsFailure: false,
    }),
    [Actions.getAllreportsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllreports: true,
      isGetAllreportsRequest: false,
      isGetAllreportsSuccess: true,
      isGetAllreportsFailure: false,
      getAllreportsState: payload,
    }),
    [Actions.getAllreportsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllreportsRequest: false,
      isGetAllreportsSuccess: false,
      isGetAllreportsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create report
    [Actions.createReportRequest]: (state) => ({
      ...state,
      isCreateReportRequest: true,
      isCreateReportSuccess: false,
      isCreateReportFailure: false,
    }),
    [Actions.createReportSuccess]: (state, { payload }) => {
      const getAllreportsStateTmp = handleUpdateDataList(
        state.getAllreportsState,
        payload.data,
        'create',
      );

      return {
        ...state,
        isCreateReportRequest: false,
        isCreateReportSuccess: true,
        isCreateReportFailure: false,
        getAllreportsState: { ...getAllreportsStateTmp },
      };
    },
    [Actions.createReportFailure]: (state, { payload }) => ({
      ...state,
      isCreateReportRequest: false,
      isCreateReportSuccess: false,
      isCreateReportFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateReport]: (state) => ({
      ...state,
      isCreateReportRequest: false,
      isCreateReportSuccess: false,
      isCreateReportFailure: false,
    }),
    // #endregion
    // #region : Update report
    [Actions.updateReportRequest]: (state) => ({
      ...state,
      isUpdateReportRequest: true,
      isUpdateReportSuccess: false,
      isUpdateReportFailure: false,
    }),
    [Actions.updateReportSuccess]: (state, { payload }) => {
      const getAllreportsStateTmp = handleUpdateDataList(
        state.getAllreportsState,
        payload.data,
        'update',
      );

      return {
        ...state,
        isUpdateReportRequest: false,
        isUpdateReportSuccess: true,
        isUpdateReportFailure: false,
        getAllreportsState: { ...getAllreportsStateTmp },
      };
    },
    [Actions.updateReportFailure]: (state, { payload }) => ({
      ...state,
      isUpdateReportRequest: false,
      isUpdateReportSuccess: false,
      isUpdateReportFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateReport]: (state) => ({
      ...state,
      isUpdateReportRequest: false,
      isUpdateReportSuccess: false,
      isUpdateReportFailure: false,
    }),
    // #endregion
    // #region : Delete report
    [Actions.deleteReportRequest]: (state) => ({
      ...state,
      isDeleteReportRequest: true,
      isDeleteReportSuccess: false,
      isDeleteReportFailure: false,
    }),
    [Actions.deleteReportSuccess]: (state, { payload }) => {
      const getAllreportsStateTmp = handleUpdateDataList(
        state.getAllreportsState,
        payload,
        'delete',
      );

      return {
        ...state,
        isDeleteReportRequest: false,
        isDeleteReportSuccess: true,
        isDeleteReportFailure: false,
        getAllreportsState: { ...getAllreportsStateTmp },
      };
    },
    [Actions.deleteReportFailure]: (state, { payload }) => ({
      ...state,
      isDeleteReportRequest: false,
      isDeleteReportSuccess: false,
      isDeleteReportFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteReport]: (state) => ({
      ...state,
      isDeleteReportRequest: false,
      isDeleteReportSuccess: false,
      isDeleteReportFailure: false,
    }),
    // #endregion
    // #region Get report warehouse detail
    [Actions.getReportWarehouseDetailRequest]: (state) => ({
      ...state,
      isGetReportWarehouseDetailRequest: true,
      isGetReportWarehouseDetailSuccess: false,
      isGetReportWarehouseDetailFailure: false,
    }),
    [Actions.getReportWarehouseDetailSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportWarehouseDetailRequest: false,
      isGetReportWarehouseDetailSuccess: true,
      isGetReportWarehouseDetailFailure: false,
      getReportWarehouseDetailState: payload,
    }),
    [Actions.getReportWarehouseDetailFailure]: (state, { payload }) => ({
      ...state,
      isGetReportWarehouseDetailRequest: false,
      isGetReportWarehouseDetailSuccess: false,
      isGetReportWarehouseDetailFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Get report warehouse general
    [Actions.getReportWarehouseGeneralRequest]: (state) => ({
      ...state,
      isGetReportWarehouseGeneralRequest: true,
      isGetReportWarehouseGeneralSuccess: false,
      isGetReportWarehouseGeneralFailure: false,
    }),
    [Actions.getReportWarehouseGeneralSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportWarehouseGeneralRequest: false,
      isGetReportWarehouseGeneralSuccess: true,
      isGetReportWarehouseGeneralFailure: false,
      getReportWarehouseGeneralState: payload,
    }),
    [Actions.getReportWarehouseGeneralFailure]: (state, { payload }) => ({
      ...state,
      isGetReportWarehouseGeneralRequest: false,
      isGetReportWarehouseGeneralSuccess: false,
      isGetReportWarehouseGeneralFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report warehouse general
    [Actions.exportReportWarehouseGeneralRequest]: (state) => ({
      ...state,
      isExportReportWarehouseGeneralRequest: true,
      isExportReportWarehouseGeneralSuccess: false,
      isExportReportWarehouseGeneralFailure: false,
    }),
    [Actions.exportReportWarehouseGeneralSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportWarehouseGeneralRequest: false,
      isExportReportWarehouseGeneralSuccess: true,
      isExportReportWarehouseGeneralFailure: false,
      exportReportWarehouseGeneralState: payload,
    }),
    [Actions.exportReportWarehouseGeneralFailure]: (state, { payload }) => ({
      ...state,
      isExportReportWarehouseGeneralRequest: false,
      isExportReportWarehouseGeneralSuccess: false,
      isExportReportWarehouseGeneralFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportWarehouseGeneral]: (state) => ({
      ...state,
      isExportReportWarehouseGeneralRequest: false,
      isExportReportWarehouseGeneralSuccess: false,
      isExportReportWarehouseGeneralFailure: false,
    }),
    // #endregion

    // #region Export excel get report warehouse detail
    [Actions.exportExcelGetReportWarehouseDetailRequest]: (state) => ({
      ...state,
      isExportExcelGetReportWarehouseDetailRequest: true,
      isExportExcelGetReportWarehouseDetailSuccess: false,
      isExportExcelGetReportWarehouseDetailFailure: false,
    }),
    [Actions.exportExcelGetReportWarehouseDetailSuccess]: (
      state,
      { payload },
    ) => ({
      ...state,
      isExportExcelGetReportWarehouseDetailRequest: false,
      isExportExcelGetReportWarehouseDetailSuccess: true,
      isExportExcelGetReportWarehouseDetailFailure: false,
      exportExcelGetReportWarehouseDetailState: payload,
    }),
    [Actions.exportExcelGetReportWarehouseDetailFailure]: (
      state,
      { payload },
    ) => ({
      ...state,
      isExportExcelGetReportWarehouseDetailRequest: false,
      isExportExcelGetReportWarehouseDetailSuccess: false,
      isExportExcelGetReportWarehouseDetailFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportExcelGetReportWarehouseDetail]: (state) => ({
      ...state,
      isExportExcelGetReportWarehouseDetailRequest: false,
      isExportExcelGetReportWarehouseDetailSuccess: false,
      isExportExcelGetReportWarehouseDetailFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report warehouse product
    [Actions.getReportWarehouseProductRequest]: (state) => ({
      ...state,
      isGetReportWarehouseProductRequest: true,
      isGetReportWarehouseProductSuccess: false,
      isGetReportWarehouseProductFailure: false,
    }),
    [Actions.getReportWarehouseProductSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportWarehouseProductRequest: false,
      isGetReportWarehouseProductSuccess: true,
      isGetReportWarehouseProductFailure: false,
      getReportWarehouseProductState: payload,
    }),
    [Actions.getReportWarehouseProductFailure]: (state, { payload }) => ({
      ...state,
      isGetReportWarehouseProductRequest: false,
      isGetReportWarehouseProductSuccess: false,
      isGetReportWarehouseProductFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export excel get report warehouse producct
    [Actions.exportExcelGetReportWarehouseProductRequest]: (state) => ({
      ...state,
      isExportExcelGetReportWarehouseProductRequest: true,
      isExportExcelGetReportWarehouseProductSuccess: false,
      isExportExcelGetReportWarehouseProductFailure: false,
    }),
    [Actions.exportExcelGetReportWarehouseProductSuccess]: (
      state,
      { payload },
    ) => ({
      ...state,
      isExportExcelGetReportWarehouseProductRequest: false,
      isExportExcelGetReportWarehouseProductSuccess: true,
      isExportExcelGetReportWarehouseProductFailure: false,
      exportExcelGetReportWarehouseProductState: payload,
    }),
    [Actions.exportExcelGetReportWarehouseProductFailure]: (
      state,
      { payload },
    ) => ({
      ...state,
      isExportExcelGetReportWarehouseProductRequest: false,
      isExportExcelGetReportWarehouseProductSuccess: false,
      isExportExcelGetReportWarehouseProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportExcelGetReportWarehouseProduct]: (state) => ({
      ...state,
      isExportExcelGetReportWarehouseProductRequest: false,
      isExportExcelGetReportWarehouseProductSuccess: false,
      isExportExcelGetReportWarehouseProductFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get list customer report
    [Actions.getListCustomerReportRequest]: (state) => ({
      ...state,
      isGetListCustomerReportRequest: true,
      isGetListCustomerReportSuccess: false,
      isGetListCustomerReportFailure: false,
    }),
    [Actions.getListCustomerReportSuccess]: (state, { payload }) => ({
      ...state,
      isGetListCustomerReportRequest: false,
      isGetListCustomerReportSuccess: true,
      isGetListCustomerReportFailure: false,
      getListCustomerReportState: payload,
    }),
    [Actions.getListCustomerReportFailure]: (state, { payload }) => ({
      ...state,
      isGetListCustomerReportRequest: false,
      isGetListCustomerReportSuccess: false,
      isGetListCustomerReportFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Get list customer report
    [Actions.getListCustomerReportDupRequest]: (state) => ({
      ...state,
      isGetListCustomerReportDupRequest: true,
      isGetListCustomerReportDupSuccess: false,
      isGetListCustomerReportDupFailure: false,
    }),
    [Actions.getListCustomerReportDupSuccess]: (state, { payload }) => ({
      ...state,
      isGetListCustomerReportDupRequest: false,
      isGetListCustomerReportDupSuccess: true,
      isGetListCustomerReportDupFailure: false,
      getListCustomerReportDupState: payload,
    }),
    [Actions.getListCustomerReportDupFailure]: (state, { payload }) => ({
      ...state,
      isGetListCustomerReportDupRequest: false,
      isGetListCustomerReportDupSuccess: false,
      isGetListCustomerReportDupFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Get report customer detail
    [Actions.getReportCustomerDetailRequest]: (state) => ({
      ...state,
      isGetReportCustomerDetailRequest: true,
      isGetReportCustomerDetailSuccess: false,
      isGetReportCustomerDetailFailure: false,
    }),
    [Actions.getReportCustomerDetailSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportCustomerDetailRequest: false,
      isGetReportCustomerDetailSuccess: true,
      isGetReportCustomerDetailFailure: false,
      getReportCustomerDetailState: payload,
    }),
    [Actions.getReportCustomerDetailFailure]: (state, { payload }) => ({
      ...state,
      isGetReportCustomerDetailRequest: false,
      isGetReportCustomerDetailSuccess: false,
      isGetReportCustomerDetailFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report customer detail
    [Actions.exportReportCustomerDetailRequest]: (state) => ({
      ...state,
      isExportReportCustomerDetailRequest: true,
      isExportReportCustomerDetailSuccess: false,
      isExportReportCustomerDetailFailure: false,
    }),
    [Actions.exportReportCustomerDetailSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportCustomerDetailRequest: false,
      isExportReportCustomerDetailSuccess: true,
      isExportReportCustomerDetailFailure: false,
      exportReportCustomerDetailState: payload,
    }),
    [Actions.exportReportCustomerDetailFailure]: (state, { payload }) => ({
      ...state,
      isExportReportCustomerDetailRequest: false,
      isExportReportCustomerDetailSuccess: false,
      isExportReportCustomerDetailFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportCustomerDetail]: (state) => ({
      ...state,
      isExportReportCustomerDetailRequest: false,
      isExportReportCustomerDetailSuccess: false,
      isExportReportCustomerDetailFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report customer export
    [Actions.getReportCustomerExportRequest]: (state) => ({
      ...state,
      isGetReportCustomerExportRequest: true,
      isGetReportCustomerExportSuccess: false,
      isGetReportCustomerExportFailure: false,
    }),
    [Actions.getReportCustomerExportSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportCustomerExportRequest: false,
      isGetReportCustomerExportSuccess: true,
      isGetReportCustomerExportFailure: false,
      getReportCustomerExportState: payload,
    }),
    [Actions.getReportCustomerExportFailure]: (state, { payload }) => ({
      ...state,
      isGetReportCustomerExportRequest: false,
      isGetReportCustomerExportSuccess: false,
      isGetReportCustomerExportFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report customer export
    [Actions.exportReportCustomerExportRequest]: (state) => ({
      ...state,
      isExportReportCustomerExportRequest: true,
      isExportReportCustomerExportSuccess: false,
      isExportReportCustomerExportFailure: false,
    }),
    [Actions.exportReportCustomerExportSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportCustomerExportRequest: false,
      isExportReportCustomerExportSuccess: true,
      isExportReportCustomerExportFailure: false,
      exportReportCustomerExportState: payload,
    }),
    [Actions.exportReportCustomerExportFailure]: (state, { payload }) => ({
      ...state,
      isExportReportCustomerExportRequest: false,
      isExportReportCustomerExportSuccess: false,
      isExportReportCustomerExportFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportCustomerExport]: (state) => ({
      ...state,
      isExportReportCustomerExportRequest: false,
      isExportReportCustomerExportSuccess: false,
      isExportReportCustomerExportFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report customer export
    [Actions.getReportProcessDetailRequest]: (state) => ({
      ...state,
      isGetReportProcessDetailRequest: true,
      isGetReportProcessDetailSuccess: false,
      isGetReportProcessDetailFailure: false,
    }),
    [Actions.getReportProcessDetailSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportProcessDetailRequest: false,
      isGetReportProcessDetailSuccess: true,
      isGetReportProcessDetailFailure: false,
      getReportProcessDetailState: payload,
    }),
    [Actions.getReportProcessDetailFailure]: (state, { payload }) => ({
      ...state,
      isGetReportProcessDetailRequest: false,
      isGetReportProcessDetailSuccess: false,
      isGetReportProcessDetailFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report process detail
    [Actions.exportReportProcessDetailRequest]: (state) => ({
      ...state,
      isExportReportProcessDetailRequest: true,
      isExportReportProcessDetailSuccess: false,
      isExportReportProcessDetailFailure: false,
    }),
    [Actions.exportReportProcessDetailSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportProcessDetailRequest: false,
      isExportReportProcessDetailSuccess: true,
      isExportReportProcessDetailFailure: false,
      exportReportProcessDetailState: payload,
    }),
    [Actions.exportReportProcessDetailFailure]: (state, { payload }) => ({
      ...state,
      isExportReportProcessDetailRequest: false,
      isExportReportProcessDetailSuccess: false,
      isExportReportProcessDetailFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportProcessDetail]: (state) => ({
      ...state,
      isExportReportProcessDetailRequest: false,
      isExportReportProcessDetailSuccess: false,
      isExportReportProcessDetailFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report customer export
    [Actions.getReportProcessTotalRequest]: (state) => ({
      ...state,
      isGetReportProcessTotalRequest: true,
      isGetReportProcessTotalSuccess: false,
      isGetReportProcessTotalFailure: false,
    }),
    [Actions.getReportProcessTotalSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportProcessTotalRequest: false,
      isGetReportProcessTotalSuccess: true,
      isGetReportProcessTotalFailure: false,
      getReportProcessTotalState: payload,
    }),
    [Actions.getReportProcessTotalFailure]: (state, { payload }) => ({
      ...state,
      isGetReportProcessTotalRequest: false,
      isGetReportProcessTotalSuccess: false,
      isGetReportProcessTotalFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report process detail
    [Actions.exportReportProcessTotalRequest]: (state) => ({
      ...state,
      isExportReportProcessTotalRequest: true,
      isExportReportProcessTotalSuccess: false,
      isExportReportProcessTotalFailure: false,
    }),
    [Actions.exportReportProcessTotalSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportProcessTotalRequest: false,
      isExportReportProcessTotalSuccess: true,
      isExportReportProcessTotalFailure: false,
      exportReportProcessTotalState: payload,
    }),
    [Actions.exportReportProcessTotalFailure]: (state, { payload }) => ({
      ...state,
      isExportReportProcessTotalRequest: false,
      isExportReportProcessTotalSuccess: false,
      isExportReportProcessTotalFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportProcessTotal]: (state) => ({
      ...state,
      isExportReportProcessTotalRequest: false,
      isExportReportProcessTotalSuccess: false,
      isExportReportProcessTotalFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report customer export
    [Actions.getReportExportDayRequest]: (state) => ({
      ...state,
      isGetReportExportDayRequest: true,
      isGetReportExportDaySuccess: false,
      isGetReportExportDayFailure: false,
    }),
    [Actions.getReportExportDaySuccess]: (state, { payload }) => ({
      ...state,
      isGetReportExportDayRequest: false,
      isGetReportExportDaySuccess: true,
      isGetReportExportDayFailure: false,
      getReportExportDayState: payload,
    }),
    [Actions.getReportExportDayFailure]: (state, { payload }) => ({
      ...state,
      isGetReportExportDayRequest: false,
      isGetReportExportDaySuccess: false,
      isGetReportExportDayFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report export day
    [Actions.exportReportExportDayRequest]: (state) => ({
      ...state,
      isExportReportExportDayRequest: true,
      isExportReportExportDaySuccess: false,
      isExportReportExportDayFailure: false,
    }),
    [Actions.exportReportExportDaySuccess]: (state, { payload }) => ({
      ...state,
      isExportReportExportDayRequest: false,
      isExportReportExportDaySuccess: true,
      isExportReportExportDayFailure: false,
      exportReportExportDayState: payload,
    }),
    [Actions.exportReportExportDayFailure]: (state, { payload }) => ({
      ...state,
      isExportReportExportDayRequest: false,
      isExportReportExportDaySuccess: false,
      isExportReportExportDayFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportExportDay]: (state) => ({
      ...state,
      isExportReportExportDayRequest: false,
      isExportReportExportDaySuccess: false,
      isExportReportExportDayFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Save export report export day
    [Actions.saveReportProcessDetailRequest]: (state) => ({
      ...state,
      isSaveReportProcessDetailRequest: true,
      isSaveReportProcessDetailSuccess: false,
      isSaveReportProcessDetailFailure: false,
    }),
    [Actions.saveReportProcessDetailSuccess]: (state) => ({
      ...state,
      isSaveReportProcessDetailRequest: false,
      isSaveReportProcessDetailSuccess: true,
      isSaveReportProcessDetailFailure: false,
    }),
    [Actions.saveReportProcessDetailFailure]: (state, { payload }) => ({
      ...state,
      isSaveReportProcessDetailRequest: false,
      isSaveReportProcessDetailSuccess: false,
      isSaveReportProcessDetailFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetSaveReportProcessDetail]: (state) => ({
      ...state,
      isSaveReportProcessDetailRequest: false,
      isSaveReportProcessDetailSuccess: false,
      isSaveReportProcessDetailFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report export date
    [Actions.getReportExportDateRequest]: (state) => ({
      ...state,
      isGetReportExportDateRequest: true,
      isGetReportExportDateSuccess: false,
      isGetReportExportDateFailure: false,
    }),
    [Actions.getReportExportDateSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportExportDateRequest: false,
      isGetReportExportDateSuccess: true,
      isGetReportExportDateFailure: false,
      getReportExportDateState: payload,
    }),
    [Actions.getReportExportDateFailure]: (state, { payload }) => ({
      ...state,
      isGetReportExportDateRequest: false,
      isGetReportExportDateSuccess: false,
      isGetReportExportDateFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Get report export date
    [Actions.getReportExportOrderDateRequest]: (state) => ({
      ...state,
      isGetReportExportOrderDateRequest: true,
      isGetReportExportOrderDateSuccess: false,
      isGetReportExportOrderDateFailure: false,
    }),
    [Actions.getReportExportOrderDateSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportExportOrderDateRequest: false,
      isGetReportExportOrderDateSuccess: true,
      isGetReportExportOrderDateFailure: false,
      getReportExportOrderDateState: payload,
    }),
    [Actions.getReportExportOrderDateFailure]: (state, { payload }) => ({
      ...state,
      isGetReportExportOrderDateRequest: false,
      isGetReportExportOrderDateSuccess: false,
      isGetReportExportOrderDateFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetReportExportOrderDate]: (state) => ({
      ...state,
      isGetReportExportOrderDateRequest: false,
      isGetReportExportOrderDateSuccess: false,
      isGetReportExportOrderDateFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Export report export date
    [Actions.exportReportExportDateRequest]: (state) => ({
      ...state,
      isExportReportExportDateRequest: true,
      isExportReportExportDateSuccess: false,
      isExportReportExportDateFailure: false,
    }),
    [Actions.exportReportExportDateSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportExportDateRequest: false,
      isExportReportExportDateSuccess: true,
      isExportReportExportDateFailure: false,
      exportReportExportDateState: payload,
    }),
    [Actions.exportReportExportDateFailure]: (state, { payload }) => ({
      ...state,
      isExportReportExportDateRequest: false,
      isExportReportExportDateSuccess: false,
      isExportReportExportDateFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportExportDate]: (state) => ({
      ...state,
      isExportReportExportDateRequest: false,
      isExportReportExportDateSuccess: false,
      isExportReportExportDateFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Export report export date
    [Actions.exportReportExportOrderDateRequest]: (state) => ({
      ...state,
      isExportReportExportOrderDateRequest: true,
      isExportReportExportOrderDateSuccess: false,
      isExportReportExportOrderDateFailure: false,
    }),
    [Actions.exportReportExportOrderDateSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportExportOrderDateRequest: false,
      isExportReportExportOrderDateSuccess: true,
      isExportReportExportOrderDateFailure: false,
      exportReportExportOrderDateState: payload,
    }),
    [Actions.exportReportExportOrderDateFailure]: (state, { payload }) => ({
      ...state,
      isExportReportExportOrderDateRequest: false,
      isExportReportExportOrderDateSuccess: false,
      isExportReportExportOrderDateFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportExportOrderDate]: (state) => ({
      ...state,
      isExportReportExportOrderDateRequest: false,
      isExportReportExportOrderDateSuccess: false,
      isExportReportExportOrderDateFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report sell day
    [Actions.getReportSellDayRequest]: (state) => ({
      ...state,
      isGetReportSellDayRequest: true,
      isGetReportSellDaySuccess: false,
      isGetReportSellDayFailure: false,
    }),
    [Actions.getReportSellDaySuccess]: (state, { payload }) => ({
      ...state,
      isGetReportSellDayRequest: false,
      isGetReportSellDaySuccess: true,
      isGetReportSellDayFailure: false,
      getReportSellDayState: payload,
    }),
    [Actions.getReportSellDayFailure]: (state, { payload }) => ({
      ...state,
      isGetReportSellDayRequest: false,
      isGetReportSellDaySuccess: false,
      isGetReportSellDayFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report export date
    [Actions.exportReportSellDayRequest]: (state) => ({
      ...state,
      isExportReportSellDayRequest: true,
      isExportReportSellDaySuccess: false,
      isExportReportSellDayFailure: false,
    }),
    [Actions.exportReportSellDaySuccess]: (state, { payload }) => ({
      ...state,
      isExportReportSellDayRequest: false,
      isExportReportSellDaySuccess: true,
      isExportReportSellDayFailure: false,
      exportReportSellDayState: payload,
    }),
    [Actions.exportReportSellDayFailure]: (state, { payload }) => ({
      ...state,
      isExportReportSellDayRequest: false,
      isExportReportSellDaySuccess: false,
      isExportReportSellDayFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportSellDay]: (state) => ({
      ...state,
      isExportReportSellDayRequest: false,
      isExportReportSellDaySuccess: false,
      isExportReportSellDayFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report customer export warehouse
    [Actions.getReportCustomerExportWarehouseRequest]: (state) => ({
      ...state,
      isGetReportCustomerExportWarehouseRequest: true,
      isGetReportCustomerExportWarehouseSuccess: false,
      isGetReportCustomerExportWarehouseFailure: false,
    }),
    [Actions.getReportCustomerExportWarehouseSuccess]: (
      state,
      { payload },
    ) => ({
      ...state,
      isGetReportCustomerExportWarehouseRequest: false,
      isGetReportCustomerExportWarehouseSuccess: true,
      isGetReportCustomerExportWarehouseFailure: false,
      getReportCustomerExportWarehouseState: payload,
    }),
    [Actions.getReportCustomerExportWarehouseFailure]: (
      state,
      { payload },
    ) => ({
      ...state,
      isGetReportCustomerExportWarehouseRequest: false,
      isGetReportCustomerExportWarehouseSuccess: false,
      isGetReportCustomerExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report export date
    [Actions.exportReportCustomerExportWarehouseRequest]: (state) => ({
      ...state,
      isExportReportCustomerExportWarehouseRequest: true,
      isExportReportCustomerExportWarehouseSuccess: false,
      isExportReportCustomerExportWarehouseFailure: false,
    }),
    [Actions.exportReportCustomerExportWarehouseSuccess]: (
      state,
      { payload },
    ) => ({
      ...state,
      isExportReportCustomerExportWarehouseRequest: false,
      isExportReportCustomerExportWarehouseSuccess: true,
      isExportReportCustomerExportWarehouseFailure: false,
      exportReportCustomerExportWarehouseState: payload,
    }),
    [Actions.exportReportCustomerExportWarehouseFailure]: (
      state,
      { payload },
    ) => ({
      ...state,
      isExportReportCustomerExportWarehouseRequest: false,
      isExportReportCustomerExportWarehouseSuccess: false,
      isExportReportCustomerExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportCustomerExportWarehouse]: (state) => ({
      ...state,
      isExportReportCustomerExportWarehouseRequest: false,
      isExportReportCustomerExportWarehouseSuccess: false,
      isExportReportCustomerExportWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report export plan
    [Actions.getReportExportPlanRequest]: (state) => ({
      ...state,
      isGetReportExportPlanRequest: true,
      isGetReportExportPlanSuccess: false,
      isGetReportExportPlanFailure: false,
    }),
    [Actions.getReportExportPlanSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportExportPlanRequest: false,
      isGetReportExportPlanSuccess: true,
      isGetReportExportPlanFailure: false,
      getReportExportPlanState: payload,
    }),
    [Actions.getReportExportPlanFailure]: (state, { payload }) => ({
      ...state,
      isGetReportExportPlanRequest: false,
      isGetReportExportPlanSuccess: false,
      isGetReportExportPlanFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report export date
    [Actions.exportReportExportPlanRequest]: (state) => ({
      ...state,
      isExportReportExportPlanRequest: true,
      isExportReportExportPlanSuccess: false,
      isExportReportExportPlanFailure: false,
    }),
    [Actions.exportReportExportPlanSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportExportPlanRequest: false,
      isExportReportExportPlanSuccess: true,
      isExportReportExportPlanFailure: false,
      exportReportExportPlanState: payload,
    }),
    [Actions.exportReportExportPlanFailure]: (state, { payload }) => ({
      ...state,
      isExportReportExportPlanRequest: false,
      isExportReportExportPlanSuccess: false,
      isExportReportExportPlanFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportExportPlan]: (state) => ({
      ...state,
      isExportReportExportPlanRequest: false,
      isExportReportExportPlanSuccess: false,
      isExportReportExportPlanFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report debt money
    [Actions.getReportDebtMoneyRequest]: (state) => ({
      ...state,
      isGetReportDebtMoneyRequest: true,
      isGetReportDebtMoneySuccess: false,
      isGetReportDebtMoneyFailure: false,
    }),
    [Actions.getReportDebtMoneySuccess]: (state, { payload }) => ({
      ...state,
      isGetReportDebtMoneyRequest: false,
      isGetReportDebtMoneySuccess: true,
      isGetReportDebtMoneyFailure: false,
      getReportDebtMoneyState: payload,
    }),
    [Actions.getReportDebtMoneyFailure]: (state, { payload }) => ({
      ...state,
      isGetReportDebtMoneyRequest: false,
      isGetReportDebtMoneySuccess: false,
      isGetReportDebtMoneyFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report debt money
    [Actions.exportReportDebtMoneyRequest]: (state) => ({
      ...state,
      isExportReportDebtMoneyRequest: true,
      isExportReportDebtMoneySuccess: false,
      isExportReportDebtMoneyFailure: false,
    }),
    [Actions.exportReportDebtMoneySuccess]: (state, { payload }) => ({
      ...state,
      isExportReportDebtMoneyRequest: false,
      isExportReportDebtMoneySuccess: true,
      isExportReportDebtMoneyFailure: false,
      exportReportDebtMoneyState: payload,
    }),
    [Actions.exportReportDebtMoneyFailure]: (state, { payload }) => ({
      ...state,
      isExportReportDebtMoneyRequest: false,
      isExportReportDebtMoneySuccess: false,
      isExportReportDebtMoneyFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportDebtMoney]: (state) => ({
      ...state,
      isExportReportDebtMoneyRequest: false,
      isExportReportDebtMoneySuccess: false,
      isExportReportDebtMoneyFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report order booking
    [Actions.getReportOrderBookingRequest]: (state) => ({
      ...state,
      isGetReportOrderBookingRequest: true,
      isGetReportOrderBookingSuccess: false,
      isGetReportOrderBookingFailure: false,
    }),
    [Actions.getReportOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportOrderBookingRequest: false,
      isGetReportOrderBookingSuccess: true,
      isGetReportOrderBookingFailure: false,
      getReportOrderBookingState: payload,
    }),
    [Actions.getReportOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isGetReportOrderBookingRequest: false,
      isGetReportOrderBookingSuccess: false,
      isGetReportOrderBookingFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export report order booking
    [Actions.exportReportOrderBookingRequest]: (state) => ({
      ...state,
      isExportReportOrderBookingRequest: true,
      isExportReportOrderBookingSuccess: false,
      isExportReportOrderBookingFailure: false,
    }),
    [Actions.exportReportOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isExportReportOrderBookingRequest: false,
      isExportReportOrderBookingSuccess: true,
      isExportReportOrderBookingFailure: false,
      exportReportOrderBookingState: payload,
    }),
    [Actions.exportReportOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isExportReportOrderBookingRequest: false,
      isExportReportOrderBookingSuccess: false,
      isExportReportOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportReportOrderBooking]: (state) => ({
      ...state,
      isExportReportOrderBookingRequest: false,
      isExportReportOrderBookingSuccess: false,
      isExportReportOrderBookingFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get report egg
    [Actions.getReportEggRequest]: (state) => ({
      ...state,
      isGetReportEggRequest: true,
      isGetReportEggSuccess: false,
      isGetReportEggFailure: false,
    }),
    [Actions.getReportEggSuccess]: (state, { payload }) => ({
      ...state,
      isGetReportEggRequest: false,
      isGetReportEggSuccess: true,
      isGetReportEggFailure: false,
      getReportEggState: payload,
    }),
    [Actions.getReportEggFailure]: (state, { payload }) => ({
      ...state,
      isGetReportEggRequest: false,
      isGetReportEggSuccess: false,
      isGetReportEggFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region : Local
    [Actions.resetReportState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
