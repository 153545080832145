/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllMarkets({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/markets?${payload}`)
        : () => axiosMicro.get('/markets', { params: payload }),
    );
    yield put(Actions.getAllMarketsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllMarketsFailure(messages));
    }
  }
}

function* createMarket({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/markets', payload));
    yield put(Actions.createMarketSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createMarketFailure(messages));
    }
  }
}

function* updateMarket({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/markets/${payload.id}`, payload.body));
    yield put(Actions.updateMarketSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateMarketFailure(messages));
    }
  }
}

function* deleteMarket({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/markets/${payload}`));
    yield put(Actions.deleteMarketSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteMarketFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllMarketsRequest, getAllMarkets);
  yield takeLatest(Actions.createMarketRequest, createMarket);
  yield takeLatest(Actions.updateMarketRequest, updateMarket);
  yield takeLatest(Actions.deleteMarketRequest, deleteMarket);
}
