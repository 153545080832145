// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All ExportPlan
  isFirstGetAllExportPlans: false,
  isGetAllExportPlansRequest: false,
  isGetAllExportPlansSuccess: false,
  isGetAllExportPlansFailure: false,
  getAllExportPlansState: {},
  // Create ExportPlan
  isCreateExportPlanRequest: false,
  isCreateExportPlanSuccess: false,
  isCreateExportPlanFailure: false,
  // Update ExportPlan
  isUpdateExportPlanRequest: false,
  isUpdateExportPlanSuccess: false,
  isUpdateExportPlanFailure: false,
  // Delete ExportPlan
  isDeleteExportPlanRequest: false,
  isDeleteExportPlanSuccess: false,
  isDeleteExportPlanFailure: false,
  // Delete ExportPlan
  isCancelExportPlanRequest: false,
  isCancelExportPlanSuccess: false,
  isCancelExportPlanFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ExportPlan
    [Actions.getAllExportPlansRequest]: (state) => ({
      ...state,
      isGetAllExportPlansRequest: true,
      isGetAllExportPlansSuccess: false,
      isGetAllExportPlansFailure: false,
    }),
    [Actions.getAllExportPlansSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllExportPlans: true,
      isGetAllExportPlansRequest: false,
      isGetAllExportPlansSuccess: true,
      isGetAllExportPlansFailure: false,
      getAllExportPlansState: payload,
    }),
    [Actions.getAllExportPlansFailure]: (state, { payload }) => ({
      ...state,
      isGetAllExportPlansRequest: false,
      isGetAllExportPlansSuccess: false,
      isGetAllExportPlansFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ExportPlan
    [Actions.createExportPlanRequest]: (state) => ({
      ...state,
      isCreateExportPlanRequest: true,
      isCreateExportPlanSuccess: false,
      isCreateExportPlanFailure: false,
    }),
    [Actions.createExportPlanSuccess]: (state, { payload }) => {
      const getAllExportPlansStateTmp = handleUpdateDataList(state.getAllExportPlansState, payload.data, 'create');

      return ({
        ...state,
        isCreateExportPlanRequest: false,
        isCreateExportPlanSuccess: true,
        isCreateExportPlanFailure: false,
        getAllExportPlansState: { ...getAllExportPlansStateTmp },
      });
    },
    [Actions.createExportPlanFailure]: (state, { payload }) => ({
      ...state,
      isCreateExportPlanRequest: false,
      isCreateExportPlanSuccess: false,
      isCreateExportPlanFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateExportPlan]: (state) => ({
      ...state,
      isCreateExportPlanRequest: false,
      isCreateExportPlanSuccess: false,
      isCreateExportPlanFailure: false,
    }),
    // #endregion
    // #region : Update ExportPlan
    [Actions.updateExportPlanRequest]: (state) => ({
      ...state,
      isUpdateExportPlanRequest: true,
      isUpdateExportPlanSuccess: false,
      isUpdateExportPlanFailure: false,
    }),
    [Actions.updateExportPlanSuccess]: (state, { payload }) => {
      const getAllExportPlansStateTmp = handleUpdateDataList(state.getAllExportPlansState, payload.data, 'update');

      return ({
        ...state,
        isUpdateExportPlanRequest: false,
        isUpdateExportPlanSuccess: true,
        isUpdateExportPlanFailure: false,
        getAllExportPlansState: { ...getAllExportPlansStateTmp },
      });
    },
    [Actions.updateExportPlanFailure]: (state, { payload }) => ({
      ...state,
      isUpdateExportPlanRequest: false,
      isUpdateExportPlanSuccess: false,
      isUpdateExportPlanFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateExportPlan]: (state) => ({
      ...state,
      isUpdateExportPlanRequest: false,
      isUpdateExportPlanSuccess: false,
      isUpdateExportPlanFailure: false,
    }),
    // #endregion
    // #region : Delete ExportPlan
    [Actions.deleteExportPlanRequest]: (state) => ({
      ...state,
      isDeleteExportPlanRequest: true,
      isDeleteExportPlanSuccess: false,
      isDeleteExportPlanFailure: false,
    }),
    [Actions.deleteExportPlanSuccess]: (state, { payload }) => {
      const getAllExportPlansStateTmp = handleUpdateDataList(state.getAllExportPlansState, payload, 'delete');

      return ({
        ...state,
        isDeleteExportPlanRequest: false,
        isDeleteExportPlanSuccess: true,
        isDeleteExportPlanFailure: false,
        getAllExportPlansState: { ...getAllExportPlansStateTmp },
      });
    },
    [Actions.deleteExportPlanFailure]: (state, { payload }) => ({
      ...state,
      isDeleteExportPlanRequest: false,
      isDeleteExportPlanSuccess: false,
      isDeleteExportPlanFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteExportPlan]: (state) => ({
      ...state,
      isDeleteExportPlanRequest: false,
      isDeleteExportPlanSuccess: false,
      isDeleteExportPlanFailure: false,
    }),
    // #endregion
    // #region : Delete ExportPlan
    [Actions.cancelExportPlanRequest]: (state) => ({
      ...state,
      isCancelExportPlanRequest: true,
      isCancelExportPlanSuccess: false,
      isCancelExportPlanFailure: false,
    }),
    [Actions.cancelExportPlanSuccess]: (state, { payload }) => {
      const getAllExportPlansStateTmp = handleUpdateDataList(state.getAllExportPlansState, payload, 'Cancel');

      return ({
        ...state,
        isCancelExportPlanRequest: false,
        isCancelExportPlanSuccess: true,
        isCancelExportPlanFailure: false,
        getAllExportPlansState: { ...getAllExportPlansStateTmp },
      });
    },
    [Actions.cancelExportPlanFailure]: (state, { payload }) => ({
      ...state,
      isCancelExportPlanRequest: false,
      isCancelExportPlanSuccess: false,
      isCancelExportPlanFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelExportPlan]: (state) => ({
      ...state,
      isCancelExportPlanRequest: false,
      isCancelExportPlanSuccess: false,
      isCancelExportPlanFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetExportPlanState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
