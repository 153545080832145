// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetListDepositOrdersRequest: false,
  isGetListDepositOrdersSuccess: false,
  isGetListDepositOrdersFailure: false,
  listDepositOrdersState: {},
  //
  isGetOrderBookingHistoryRequest: false,
  isGetOrderBookingHistorySuccess: false,
  isGetOrderBookingHistoryFailure: false,
  listOrderBookingHistory: {},
  //
  isCreateOrderBookingRequest: false,
  isCreateOrderBookingSuccess: false,
  isCreateOrderBookingFailure: false,
  createOrderBookingResponse: {},
  //
  isUpdateOrderBookingRequest: false,
  isUpdateOrderBookingSuccess: false,
  isUpdateOrderBookingFailure: false,
  responseUpdateOrderBooking: {},
  //
  isActiveOrderBookingRequest: false,
  isActiveOrderBookingSuccess: false,
  isActiveOrderBookingFailure: false,
  responseActiveOrderBooking: {},
  //
  isDoneOrderBookingRequest: false,
  isDoneOrderBookingSuccess: false,
  isDoneOrderBookingFailure: false,
  responseDoneOrderBooking: {},
  //
  isCancelOrderBookingRequest: false,
  isCancelOrderBookingSuccess: false,
  isCancelOrderBookingFailure: false,
  responseCancelOrderBooking: {},
  //
  isGetMoneyBookingOrderRequest: false,
  isGetMoneyBookingOrderSuccess: false,
  isGetMoneyBookingOrderFailure: false,
  responseGetMoneyBookingOrder: {},
  //
};
const reducer = handleActions(
  {
    // #region: get list deposit orders
    [Actions.getListDepositOrdersRequest]: (state) => ({
      ...state,
      isGetListDepositOrdersRequest: true,
      isGetListDepositOrdersSuccess: false,
      isGetListDepositOrdersFailure: false,
    }),
    [Actions.getListDepositOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isGetListDepositOrdersRequest: false,
      isGetListDepositOrdersSuccess: true,
      isGetListDepositOrdersFailure: false,
      listDepositOrdersState: payload,
    }),
    [Actions.getListDepositOrdersFailure]: (state, { payload }) => ({
      ...state,
      isGetListDepositOrdersRequest: false,
      isGetListDepositOrdersSuccess: false,
      isGetListDepositOrdersFailure: true,
      errorMessages: payload,
    }),
    // #region: Get order booking history
    [Actions.getOrderBookingHistoryRequest]: (state) => ({
      ...state,
      isGetOrderBookingHistoryRequest: true,
      isGetOrderBookingHistorySuccess: false,
      isGetOrderBookingHistoryFailure: false,
    }),
    [Actions.getOrderBookingHistorySuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderBookingHistoryRequest: false,
      isGetOrderBookingHistorySuccess: true,
      isGetOrderBookingHistoryFailure: false,
      listOrderBookingHistory: payload,
    }),
    [Actions.getOrderBookingHistoryFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderBookingHistoryRequest: false,
      isGetOrderBookingHistorySuccess: false,
      isGetOrderBookingHistoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetOrderBookingHistoryState]: (state) => ({
      ...state,
      isGetOrderBookingHistoryRequest: false,
      isGetOrderBookingHistorySuccess: false,
      isGetOrderBookingHistoryFailure: false,
      listOrderBookingHistory: {},
    }),
    // #endregion
    // #region: Create order booking
    [Actions.createOrderBookingRequest]: (state) => ({
      ...state,
      isCreateOrderBookingRequest: true,
      isCreateOrderBookingSuccess: false,
      isCreateOrderBookingFailure: false,
    }),
    [Actions.createOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isCreateOrderBookingRequest: false,
      isCreateOrderBookingSuccess: true,
      isCreateOrderBookingFailure: false,
      createOrderBookingResponse: payload,
    }),
    [Actions.createOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isCreateOrderBookingRequest: false,
      isCreateOrderBookingSuccess: false,
      isCreateOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateOrderBookingState]: (state) => ({
      ...state,
      isCreateOrderBookingRequest: false,
      isCreateOrderBookingSuccess: false,
      isCreateOrderBookingFailure: false,
      createOrderBookingResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region: Update order booking
    [Actions.updateOrderBookingRequest]: (state) => ({
      ...state,
      isUpdateOrderBookingRequest: true,
      isUpdateOrderBookingSuccess: false,
      isUpdateOrderBookingFailure: false,
    }),
    [Actions.updateOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateOrderBookingRequest: false,
      isUpdateOrderBookingSuccess: true,
      isUpdateOrderBookingFailure: false,
      responseUpdateOrderBooking: payload,
    }),
    [Actions.updateOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isUpdateOrderBookingRequest: false,
      isUpdateOrderBookingSuccess: false,
      isUpdateOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateOrderBookingState]: (state) => ({
      ...state,
      isUpdateOrderBookingRequest: false,
      isUpdateOrderBookingSuccess: false,
      isUpdateOrderBookingFailure: false,
      responseUpdateOrderBooking: {},
    }),
    // #endregion
    // #region: Active order booking
    [Actions.activeOrderBookingRequest]: (state) => ({
      ...state,
      isActiveOrderBookingRequest: true,
      isActiveOrderBookingSuccess: false,
      isActiveOrderBookingFailure: false,
    }),
    [Actions.activeOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isActiveOrderBookingRequest: false,
      isActiveOrderBookingSuccess: true,
      isActiveOrderBookingFailure: false,
      responseActiveOrderBooking: payload,
    }),
    [Actions.activeOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isActiveOrderBookingRequest: false,
      isActiveOrderBookingSuccess: false,
      isActiveOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetActiveOrderBookingState]: (state) => ({
      ...state,
      isActiveOrderBookingRequest: false,
      isActiveOrderBookingSuccess: false,
      isActiveOrderBookingFailure: false,
      responseActiveOrderBooking: {},
    }),
    // #endregion
    // #region: Done order booking
    [Actions.doneOrderBookingRequest]: (state) => ({
      ...state,
      isDoneOrderBookingRequest: true,
      isDoneOrderBookingSuccess: false,
      isDoneOrderBookingFailure: false,
    }),
    [Actions.doneOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isDoneOrderBookingRequest: false,
      isDoneOrderBookingSuccess: true,
      isDoneOrderBookingFailure: false,
      responseDoneOrderBooking: payload,
    }),
    [Actions.doneOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isDoneOrderBookingRequest: false,
      isDoneOrderBookingSuccess: false,
      isDoneOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDoneOrderBookingState]: (state) => ({
      ...state,
      isDoneOrderBookingRequest: false,
      isDoneOrderBookingSuccess: false,
      isDoneOrderBookingFailure: false,
      responseDoneOrderBooking: {},
    }),
    // #endregion
    // #region: Cancel order booking
    [Actions.cancelOrderBookingRequest]: (state) => ({
      ...state,
      isCancelOrderBookingRequest: true,
      isCancelOrderBookingSuccess: false,
      isCancelOrderBookingFailure: false,
    }),
    [Actions.cancelOrderBookingSuccess]: (state, { payload }) => ({
      ...state,
      isCancelOrderBookingRequest: false,
      isCancelOrderBookingSuccess: true,
      isCancelOrderBookingFailure: false,
      responseCancelOrderBooking: payload,
    }),
    [Actions.cancelOrderBookingFailure]: (state, { payload }) => ({
      ...state,
      isCancelOrderBookingRequest: false,
      isCancelOrderBookingSuccess: false,
      isCancelOrderBookingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelOrderBookingState]: (state) => ({
      ...state,
      isCancelOrderBookingRequest: false,
      isCancelOrderBookingSuccess: false,
      isCancelOrderBookingFailure: false,
      responseCancelOrderBooking: {},
    }),
    // #endregion
    // #region: Get money booking order
    [Actions.getMoneyBookingOrderRequest]: (state) => ({
      ...state,
      isGetMoneyBookingOrderRequest: true,
      isGetMoneyBookingOrderSuccess: false,
      isGetMoneyBookingOrderFailure: false,
    }),
    [Actions.getMoneyBookingOrderSuccess]: (state, { payload }) => ({
      ...state,
      isGetMoneyBookingOrderRequest: false,
      isGetMoneyBookingOrderSuccess: true,
      isGetMoneyBookingOrderFailure: false,
      responseGetMoneyBookingOrder: payload,
    }),
    [Actions.getMoneyBookingOrderFailure]: (state, { payload }) => ({
      ...state,
      isGetMoneyBookingOrderRequest: false,
      isGetMoneyBookingOrderSuccess: false,
      isGetMoneyBookingOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetMoneyBookingOrderState]: (state) => ({
      ...state,
      isGetMoneyBookingOrderRequest: false,
      isGetMoneyBookingOrderSuccess: false,
      isGetMoneyBookingOrderFailure: false,
      responseGetMoneyBookingOrder: {},
    }),
    // #endregion
  },

  initialState,
);
export default reducer;
