// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  //
  isGetAllSupervisorsRequest: false,
  isGetAllSupervisorsSuccess: false,
  isGetAllSupervisorsFailure: false,
  allSupervisors: {},
  //
  isCreateSupervisorsRequest: false,
  isCreateSupervisorsSuccess: false,
  isCreateSupervisorsFailure: false,
  //
  isUpdateSupervisorsRequest: false,
  isUpdateSupervisorsSuccess: false,
  isUpdateSupervisorsFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all supervisors
    [Actions.getAllSupervisorsRequest]: (state) => ({
      ...state,
      isGetAllSupervisorsRequest: true,
      isGetAllSupervisorsSuccess: false,
      isGetAllSupervisorsFailure: false,
    }),
    [Actions.getAllSupervisorsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllSupervisorsRequest: false,
      isGetAllSupervisorsSuccess: true,
      isGetAllSupervisorsFailure: false,
      allSupervisors: payload,
    }),
    [Actions.getAllSupervisorsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllSupervisorsRequest: false,
      isGetAllSupervisorsSuccess: false,
      isGetAllSupervisorsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Supervisors
    [Actions.createSupervisorsRequest]: (state) => ({
      ...state,
      isCreateSupervisorsRequest: true,
      isCreateSupervisorsSuccess: false,
      isCreateSupervisorsFailure: false,
    }),
    [Actions.createSupervisorsSuccess]: (state, { payload }) => {
      const getAllSupervisorsStateTmp = handleUpdateDataList(state.allSupervisors, payload.data, 'create');

      return ({
        ...state,
        isCreateSupervisorsRequest: false,
        isCreateSupervisorsSuccess: true,
        isCreateSupervisorsFailure: false,
        allSupervisors: { ...getAllSupervisorsStateTmp },
      });
    },
    [Actions.createSupervisorsFailure]: (state, { payload }) => ({
      ...state,
      isCreateSupervisorsRequest: false,
      isCreateSupervisorsSuccess: false,
      isCreateSupervisorsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateSupervisorsState]: (state) => ({
      ...state,
      isCreateSupervisorsRequest: false,
      isCreateSupervisorsSuccess: false,
      isCreateSupervisorsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update Supervisors
    [Actions.updateSupervisorsRequest]: (state) => ({
      ...state,
      isUpdateSupervisorsRequest: true,
      isUpdateSupervisorsSuccess: false,
      isUpdateSupervisorsFailure: false,
    }),
    [Actions.updateSupervisorsSuccess]: (state, { payload }) => {
      const getAllSupervisorsStateTmp = handleUpdateDataList(state.allSupervisors, payload.data, 'update');

      return ({
        ...state,
        isUpdateSupervisorsRequest: false,
        isUpdateSupervisorsSuccess: true,
        isUpdateSupervisorsFailure: false,
        allSupervisors: { ...getAllSupervisorsStateTmp },
      });
    },
    [Actions.updateSupervisorsFailure]: (state, { payload }) => ({
      ...state,
      isUpdateSupervisorsRequest: false,
      isUpdateSupervisorsSuccess: false,
      isUpdateSupervisorsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateSupervisorsState]: (state) => ({
      ...state,
      isUpdateSupervisorsRequest: false,
      isUpdateSupervisorsSuccess: false,
      isUpdateSupervisorsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    // #endregion
  },
  initialState,
);

export default reducer;
