/* eslint-disable func-names */
/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListDepositOrders({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params
        ? `/order-bookings?include=customer&${payload.params}`
        : '/order-bookings?include=customer',
      {
        params: {
          timestamp: new Date().getTime(),
        },
      },
    ));
    yield put(Actions.getListDepositOrdersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListDepositOrdersFailure(messages));
    }
  }
}

function* createOrderBooking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/order-bookings', payload));
    if (response?.status === 201) {
      yield put(Actions.createOrderBookingSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createOrderBookingFailure(messages));
    }
  }
}

function* updateOrderBooking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/order-bookings/${payload.id}`, payload.params));
    yield put(Actions.updateOrderBookingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateOrderBookingFailure(messages));
    }
  }
}

function* activeOrderBooking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/order-bookings/${payload}/active`));
    yield put(Actions.activeOrderBookingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateOrderBookingFailure(messages));
    }
  }
}

function* doneOrderBooking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/order-bookings/${payload.id}/done`, {
      reason: payload.reason,
    }));
    yield put(Actions.doneOrderBookingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.doneOrderBookingFailure(messages));
    }
  }
}

function* cancelOrderBooking({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/order-bookings/${payload.id}/cancel`, {
      reason: payload.reason,
    }));
    yield put(Actions.cancelOrderBookingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.cancelOrderBookingFailure(messages));
    }
  }
}

function* getMoneyBookingOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/orders/money-booking', {
      params: payload,
    }));
    yield put(Actions.getMoneyBookingOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getMoneyBookingOrderFailure(messages));
    }
  }
}

function* getOrderBookingHistory({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/order-bookings/${payload}/histories`));
    yield put(Actions.getOrderBookingHistorySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrderBookingHistoryFailure(messages));
    }
  }
}
export default function* () {
  yield takeLatest(Actions.getListDepositOrdersRequest, getListDepositOrders);
  yield takeLatest(Actions.getOrderBookingHistoryRequest, getOrderBookingHistory);
  yield takeLatest(Actions.createOrderBookingRequest, createOrderBooking);
  yield takeLatest(Actions.updateOrderBookingRequest, updateOrderBooking);
  yield takeLatest(Actions.activeOrderBookingRequest, activeOrderBooking);
  yield takeLatest(Actions.doneOrderBookingRequest, doneOrderBooking);
  yield takeLatest(Actions.cancelOrderBookingRequest, cancelOrderBooking);
  yield takeLatest(Actions.getMoneyBookingOrderRequest, getMoneyBookingOrder);
}
