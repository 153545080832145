// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllCustomerExportsRequest = createAction('GET_ALL_CUSTOMEREXPORTS_REQUEST');
export const getAllCustomerExportsSuccess = createAction('GET_ALL_CUSTOMEREXPORTS_SUCCESS');
export const getAllCustomerExportsFailure = createAction('GET_ALL_CUSTOMEREXPORTS_FAILURE');

export const createCustomerExportRequest = createAction('CREATE_CUSTOMEREXPORT_REQUEST');
export const createCustomerExportSuccess = createAction('CREATE_CUSTOMEREXPORT_SUCCESS');
export const createCustomerExportFailure = createAction('CREATE_CUSTOMEREXPORT_FAILURE');
export const resetCreateCustomerExport = createAction('RESET_CREATE_CUSTOMEREXPORT');

export const updateCustomerExportRequest = createAction('UPDATE_CUSTOMEREXPORT_REQUEST');
export const updateCustomerExportSuccess = createAction('UPDATE_CUSTOMEREXPORT_SUCCESS');
export const updateCustomerExportFailure = createAction('UPDATE_CUSTOMEREXPORT_FAILURE');
export const resetUpdateCustomerExport = createAction('RESET_UPDATE_CUSTOMEREXPORT');

export const deleteCustomerExportRequest = createAction('DELETE_CUSTOMEREXPORT_REQUEST');
export const deleteCustomerExportSuccess = createAction('DELETE_CUSTOMEREXPORT_SUCCESS');
export const deleteCustomerExportFailure = createAction('DELETE_CUSTOMEREXPORT_FAILURE');
export const resetDeleteCustomerExport = createAction('RESET_DELETE_CUSTOMEREXPORT');

export const resetCustomerExportState = createAction('RESET_CUSTOMEREXPORT_STATE');
