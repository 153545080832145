// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All Driver
  isFirstGetAllDrivers: false,
  isGetAllDriversRequest: false,
  isGetAllDriversSuccess: false,
  isGetAllDriversFailure: false,
  getAllDriversState: {},
  // Create Driver
  isCreateDriverRequest: false,
  isCreateDriverSuccess: false,
  isCreateDriverFailure: false,
  // Update Driver
  isUpdateDriverRequest: false,
  isUpdateDriverSuccess: false,
  isUpdateDriverFailure: false,
  // Delete Driver
  isDeleteDriverRequest: false,
  isDeleteDriverSuccess: false,
  isDeleteDriverFailure: false,
  // Update export driver
  isUpdateExportDriverRequest: false,
  isUpdateExportDriverSuccess: false,
  isUpdateExportDriverFailure: false,
  updateExportDriverState: {},
  // Get driver export
  isGetDriverExportRequest: false,
  isGetDriverExportSuccess: false,
  isGetDriverExportFailure: false,
  getDriverExportState: {},
  // get one driver export
  isGetOneDriverExportRequest: false,
  isGetOneDriverExportSuccess: false,
  isGetOneDriverExportFailure: false,
  getOneDriverExportState: {},
  // update driver sign
  isUpdateDriverSignRequest: false,
  isUpdateDriverSignSuccess: false,
  isUpdateDriverSignFailure: false,
  // update sign export driver
  isUpdateSignExportDriverRequest: false,
  isUpdateSignExportDriverSuccess: false,
  isUpdateSignExportDriverFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Driver
    [Actions.getAllDriversRequest]: (state) => ({
      ...state,
      isGetAllDriversRequest: true,
      isGetAllDriversSuccess: false,
      isGetAllDriversFailure: false,
    }),
    [Actions.getAllDriversSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllDrivers: true,
      isGetAllDriversRequest: false,
      isGetAllDriversSuccess: true,
      isGetAllDriversFailure: false,
      getAllDriversState: payload,
    }),
    [Actions.getAllDriversFailure]: (state, { payload }) => ({
      ...state,
      isGetAllDriversRequest: false,
      isGetAllDriversSuccess: false,
      isGetAllDriversFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Driver
    [Actions.createDriverRequest]: (state) => ({
      ...state,
      isCreateDriverRequest: true,
      isCreateDriverSuccess: false,
      isCreateDriverFailure: false,
    }),
    [Actions.createDriverSuccess]: (state, { payload }) => {
      const getAllDriversStateTmp = handleUpdateDataList(
        state.getAllDriversState,
        payload.data,
        'create',
      );

      return {
        ...state,
        isCreateDriverRequest: false,
        isCreateDriverSuccess: true,
        isCreateDriverFailure: false,
        getAllDriversState: { ...getAllDriversStateTmp },
      };
    },
    [Actions.createDriverFailure]: (state, { payload }) => ({
      ...state,
      isCreateDriverRequest: false,
      isCreateDriverSuccess: false,
      isCreateDriverFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateDriver]: (state) => ({
      ...state,
      isCreateDriverRequest: false,
      isCreateDriverSuccess: false,
      isCreateDriverFailure: false,
    }),
    // #endregion
    // #region : Update Driver
    [Actions.updateDriverRequest]: (state) => ({
      ...state,
      isUpdateDriverRequest: true,
      isUpdateDriverSuccess: false,
      isUpdateDriverFailure: false,
    }),
    [Actions.updateDriverSuccess]: (state, { payload }) => {
      const getAllDriversStateTmp = handleUpdateDataList(
        state.getAllDriversState,
        payload.data,
        'update',
      );

      return {
        ...state,
        isUpdateDriverRequest: false,
        isUpdateDriverSuccess: true,
        isUpdateDriverFailure: false,
        getAllDriversState: { ...getAllDriversStateTmp },
      };
    },
    [Actions.updateDriverFailure]: (state, { payload }) => ({
      ...state,
      isUpdateDriverRequest: false,
      isUpdateDriverSuccess: false,
      isUpdateDriverFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateDriver]: (state) => ({
      ...state,
      isUpdateDriverRequest: false,
      isUpdateDriverSuccess: false,
      isUpdateDriverFailure: false,
    }),
    // #endregion
    // #region : Delete Driver
    [Actions.deleteDriverRequest]: (state) => ({
      ...state,
      isDeleteDriverRequest: true,
      isDeleteDriverSuccess: false,
      isDeleteDriverFailure: false,
    }),
    [Actions.deleteDriverSuccess]: (state, { payload }) => {
      const getAllDriversStateTmp = handleUpdateDataList(
        state.getAllDriversState,
        payload,
        'delete',
      );

      return {
        ...state,
        isDeleteDriverRequest: false,
        isDeleteDriverSuccess: true,
        isDeleteDriverFailure: false,
        getAllDriversState: { ...getAllDriversStateTmp },
      };
    },
    [Actions.deleteDriverFailure]: (state, { payload }) => ({
      ...state,
      isDeleteDriverRequest: false,
      isDeleteDriverSuccess: false,
      isDeleteDriverFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteDriver]: (state) => ({
      ...state,
      isDeleteDriverRequest: false,
      isDeleteDriverSuccess: false,
      isDeleteDriverFailure: false,
    }),
    // #endregion
    // #region : Update export Driver
    [Actions.updateExportDriverRequest]: (state) => ({
      ...state,
      isUpdateExportDriverRequest: true,
      isUpdateExportDriverSuccess: false,
      isUpdateExportDriverFailure: false,
    }),
    [Actions.updateExportDriverSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateExportDriverRequest: false,
      isUpdateExportDriverSuccess: true,
      isUpdateExportDriverFailure: false,
      updateExportDriverState: payload,
    }),
    [Actions.updateExportDriverFailure]: (state, { payload }) => ({
      ...state,
      isUpdateExportDriverRequest: false,
      isUpdateExportDriverSuccess: false,
      isUpdateExportDriverFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateExportDriver]: (state) => ({
      ...state,
      isUpdateExportDriverRequest: false,
      isUpdateExportDriverSuccess: false,
      isUpdateExportDriverFailure: false,
    }),
    // #endregion
    // #region : Get driver export
    [Actions.getDriverExportRequest]: (state) => ({
      ...state,
      isGetDriverExportRequest: true,
      isGetDriverExportSuccess: false,
      isGetDriverExportFailure: false,
    }),
    [Actions.getDriverExportSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetDriverExport: true,
      isGetDriverExportRequest: false,
      isGetDriverExportSuccess: true,
      isGetDriverExportFailure: false,
      getDriverExportState: payload,
    }),
    [Actions.getDriverExportFailure]: (state, { payload }) => ({
      ...state,
      isGetDriverExportRequest: false,
      isGetDriverExportSuccess: false,
      isGetDriverExportFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get one driver export
    [Actions.getOneDriverExportRequest]: (state) => ({
      ...state,
      isGetOneDriverExportRequest: true,
      isGetOneDriverExportSuccess: false,
      isGetOneDriverExportFailure: false,
    }),
    [Actions.getOneDriverExportSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetOneDriverExport: true,
      isGetOneDriverExportRequest: false,
      isGetOneDriverExportSuccess: true,
      isGetOneDriverExportFailure: false,
      getOneDriverExportState: payload,
    }),
    [Actions.getOneDriverExportFailure]: (state, { payload }) => ({
      ...state,
      isGetOneDriverExportRequest: false,
      isGetOneDriverExportSuccess: false,
      isGetOneDriverExportFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region Update driver sign
    [Actions.updateDriverSignRequest]: (state) => ({
      ...state,
      isUpdateDriverSignRequest: true,
      isUpdateDriverSignSuccess: false,
      isUpdateDriverSignFailure: false,
    }),
    [Actions.updateDriverSignSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateDriverSignRequest: false,
      isUpdateDriverSignSuccess: true,
      isUpdateDriverSignFailure: false,
      updateDriverSignState: payload,
    }),
    [Actions.updateDriverSignFailure]: (state, { payload }) => ({
      ...state,
      isUpdateDriverSignRequest: false,
      isUpdateDriverSignSuccess: false,
      isUpdateDriverSignFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateDriverSign]: (state) => ({
      ...state,
      isUpdateDriverSignRequest: false,
      isUpdateDriverSignSuccess: false,
      isUpdateDriverSignFailure: false,
    }),
    // #endregion
    // #region Update sign export driver
    [Actions.updateSignExportDriverRequest]: (state) => ({
      ...state,
      isUpdateSignExportDriverRequest: true,
      isUpdateSignExportDriverSuccess: false,
      isUpdateSignExportDriverFailure: false,
    }),
    [Actions.updateSignExportDriverSuccess]: (state) => ({
      ...state,
      isUpdateSignExportDriverRequest: false,
      isUpdateSignExportDriverSuccess: true,
      isUpdateSignExportDriverFailure: false,
    }),
    [Actions.updateSignExportDriverFailure]: (state, { payload }) => ({
      ...state,
      isUpdateSignExportDriverRequest: false,
      isUpdateSignExportDriverSuccess: false,
      isUpdateSignExportDriverFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateSignExportDriver]: (state) => ({
      ...state,
      isUpdateSignExportDriverRequest: false,
      isUpdateSignExportDriverSuccess: false,
      isUpdateSignExportDriverFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetDriverState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
