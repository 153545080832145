// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllSupervisorsRequest = createAction(
  'GET_ALL_SUPERVISORS_REQUEST',
);
export const getAllSupervisorsSuccess = createAction(
  'GET_ALL_SUPERVISORS_SUCCESS',
);
export const getAllSupervisorsFailure = createAction(
  'GET_ALL_SUPERVISORS_FAILURE',
);

export const createSupervisorsRequest = createAction(
  'CREATE_SUPERVISORS_REQUEST',
);
export const createSupervisorsSuccess = createAction(
  'CREATE_SUPERVISORS_SUCCESS',
);
export const createSupervisorsFailure = createAction(
  'CREATE_SUPERVISORS_FAILURE',
);
export const resetCreateSupervisorsState = createAction(
  'RESET_CREATE_SUPERVISORS_STATE',
);

export const updateSupervisorsRequest = createAction(
  'UPDATE_SUPERVISORS_REQUEST',
);
export const updateSupervisorsSuccess = createAction(
  'UPDATE_SUPERVISORS_SUCCESS',
);
export const updateSupervisorsFailure = createAction(
  'UPDATE_SUPERVISORS_FAILURE',
);
export const resetUpdateSupervisorsState = createAction(
  'RESET_UPDATE_SUPERVISORS_STATE',
);
