// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  //
  isGetAllMarketsRequest: false,
  isGetAllMarketsSuccess: false,
  isGetAllMarketsFailure: false,
  allMarkets: {},
  //
  isCreateMarketsRequest: false,
  isCreateMarketsSuccess: false,
  isCreateMarketsFailure: false,
  //
  isUpdateMarketsRequest: false,
  isUpdateMarketsSuccess: false,
  isUpdateMarketsFailure: false,
  //
  isDeleteMarketsRequest: false,
  isDeleteMarketsSuccess: false,
  isDeleteMarketsFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all markets
    [Actions.getAllMarketsRequest]: (state) => ({
      ...state,
      isGetAllMarketsRequest: true,
      isGetAllMarketsSuccess: false,
      isGetAllMarketsFailure: false,
    }),
    [Actions.getAllMarketsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllMarketsRequest: false,
      isGetAllMarketsSuccess: true,
      isGetAllMarketsFailure: false,
      allMarkets: payload,
    }),
    [Actions.getAllMarketsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllMarketsRequest: false,
      isGetAllMarketsSuccess: false,
      isGetAllMarketsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create markets
    [Actions.createMarketsRequest]: (state) => ({
      ...state,
      isCreateMarketsRequest: true,
      isCreateMarketsSuccess: false,
      isCreateMarketsFailure: false,
    }),
    [Actions.createMarketsSuccess]: (state, { payload }) => {
      const getAllMarketsStateTmp = handleUpdateDataList(state.allMarkets, payload.data, 'create');

      return ({
        ...state,
        isCreateMarketsRequest: false,
        isCreateMarketsSuccess: true,
        isCreateMarketsFailure: false,
        allMarkets: { ...getAllMarketsStateTmp },
      });
    },
    [Actions.createMarketsFailure]: (state, { payload }) => ({
      ...state,
      isCreateMarketsRequest: false,
      isCreateMarketsSuccess: false,
      isCreateMarketsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateMarketsState]: (state) => ({
      ...state,
      isCreateMarketsRequest: false,
      isCreateMarketsSuccess: false,
      isCreateMarketsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update markets
    [Actions.updateMarketsRequest]: (state) => ({
      ...state,
      isUpdateMarketsRequest: true,
      isUpdateMarketsSuccess: false,
      isUpdateMarketsFailure: false,
    }),
    [Actions.updateMarketsSuccess]: (state, { payload }) => {
      const getAllMarketsStateTmp = handleUpdateDataList(state.allMarkets, payload.data, 'update');

      return ({
        ...state,
        isUpdateMarketsRequest: false,
        isUpdateMarketsSuccess: true,
        isUpdateMarketsFailure: false,
        allMarkets: { ...getAllMarketsStateTmp },
      });
    },
    [Actions.updateMarketsFailure]: (state, { payload }) => ({
      ...state,
      isUpdateMarketsRequest: false,
      isUpdateMarketsSuccess: false,
      isUpdateMarketsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateMarketsState]: (state) => ({
      ...state,
      isUpdateMarketsRequest: false,
      isUpdateMarketsSuccess: false,
      isUpdateMarketsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete markets
    [Actions.deleteMarketsRequest]: (state) => ({
      ...state,
      isDeleteMarketsRequest: true,
      isDeleteMarketsSuccess: false,
      isDeleteMarketsFailure: false,
    }),
    [Actions.deleteMarketsSuccess]: (state, { payload }) => {
      const getAllMarketsStateTmp = handleUpdateDataList(state.allMarkets, payload.data, 'delete');
      return ({
        ...state,
        isDeleteMarketsRequest: false,
        isDeleteMarketsSuccess: true,
        isDeleteMarketsFailure: false,
        allMarkets: { ...getAllMarketsStateTmp },
      });
    },
    [Actions.deleteMarketsFailure]: (state, { payload }) => ({
      ...state,
      isDeleteMarketsRequest: false,
      isDeleteMarketsSuccess: false,
      isDeleteMarketsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteMarketsState]: (state) => ({
      ...state,
      isDeleteMarketsRequest: false,
      isDeleteMarketsSuccess: false,
      isDeleteMarketsFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local

    // #endregion
  },
  initialState,
);

export default reducer;
