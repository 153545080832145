// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  // Get All ExportProduct
  isFirstGetAllExportProducts: false,
  isGetAllExportProductsRequest: false,
  isGetAllExportProductsSuccess: false,
  isGetAllExportProductsFailure: false,
  getAllExportProductsState: {},
  // Create ExportProduct
  isCreateExportProductRequest: false,
  isCreateExportProductSuccess: false,
  isCreateExportProductFailure: false,
  // Cancel ExportProduct
  isCancelExportProductRequest: false,
  isCancelExportProductSuccess: false,
  isCancelExportProductFailure: false,
  // Update Status ExportProduct
  isUpdateStatusExportProductRequest: false,
  isUpdateStatusExportProductSuccess: false,
  isUpdateStatusExportProductFailure: false,
  // Update Status ExportProduct
  isUpdateExportProductRequest: false,
  isUpdateExportProductSuccess: false,
  isUpdateExportProductFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ExportProduct
    [Actions.getAllExportProductsRequest]: (state) => ({
      ...state,
      isGetAllExportProductsRequest: true,
      isGetAllExportProductsSuccess: false,
      isGetAllExportProductsFailure: false,
    }),
    [Actions.getAllExportProductsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllExportProducts: true,
      isGetAllExportProductsRequest: false,
      isGetAllExportProductsSuccess: true,
      isGetAllExportProductsFailure: false,
      getAllExportProductsState: payload,
    }),
    [Actions.getAllExportProductsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllExportProductsRequest: false,
      isGetAllExportProductsSuccess: false,
      isGetAllExportProductsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ExportProduct
    [Actions.createExportProductRequest]: (state) => ({
      ...state,
      isCreateExportProductRequest: true,
      isCreateExportProductSuccess: false,
      isCreateExportProductFailure: false,
    }),
    [Actions.createExportProductSuccess]: (state) => ({
      ...state,
      isCreateExportProductRequest: false,
      isCreateExportProductSuccess: true,
      isCreateExportProductFailure: false,
    }),
    [Actions.createExportProductFailure]: (state, { payload }) => ({
      ...state,
      isCreateExportProductRequest: false,
      isCreateExportProductSuccess: false,
      isCreateExportProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateExportProduct]: (state) => ({
      ...state,
      isCreateExportProductRequest: false,
      isCreateExportProductSuccess: false,
      isCreateExportProductFailure: false,
    }),
    // #endregion
    // #region : Update ExportProduct
    [Actions.updateExportProductRequest]: (state) => ({
      ...state,
      isUpdateExportProductRequest: true,
      isUpdateExportProductSuccess: false,
      isUpdateExportProductFailure: false,
    }),
    [Actions.updateExportProductSuccess]: (state) => ({
      ...state,
      isUpdateExportProductRequest: false,
      isUpdateExportProductSuccess: true,
      isUpdateExportProductFailure: false,
    }),
    [Actions.updateExportProductFailure]: (state, { payload }) => ({
      ...state,
      isUpdateExportProductRequest: false,
      isUpdateExportProductSuccess: false,
      isUpdateExportProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateExportProduct]: (state) => ({
      ...state,
      isUpdateExportProductRequest: false,
      isUpdateExportProductSuccess: false,
      isUpdateExportProductFailure: false,
    }),
    // #endregion
    // #region : Cancel ExportProduct
    [Actions.cancelExportProductRequest]: (state) => ({
      ...state,
      isCancelExportProductRequest: true,
      isCancelExportProductSuccess: false,
      isCancelExportProductFailure: false,
    }),
    [Actions.cancelExportProductSuccess]: (state) => ({
      ...state,
      isCancelExportProductRequest: false,
      isCancelExportProductSuccess: true,
      isCancelExportProductFailure: false,
    }),
    [Actions.cancelExportProductFailure]: (state, { payload }) => ({
      ...state,
      isCancelExportProductRequest: false,
      isCancelExportProductSuccess: false,
      isCancelExportProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelExportProduct]: (state) => ({
      ...state,
      isCancelExportProductRequest: false,
      isCancelExportProductSuccess: false,
      isCancelExportProductFailure: false,
    }),
    // #endregion
    // #region : Update Status ExportProduct
    [Actions.updateStatusExportProductRequest]: (state) => ({
      ...state,
      isUpdateStatusExportProductRequest: true,
      isUpdateStatusExportProductSuccess: false,
      isUpdateStatusExportProductFailure: false,
    }),
    [Actions.updateStatusExportProductSuccess]: (state) => ({
      ...state,
      isUpdateStatusExportProductRequest: false,
      isUpdateStatusExportProductSuccess: true,
      isUpdateStatusExportProductFailure: false,
    }),
    [Actions.updateStatusExportProductFailure]: (state, { payload }) => ({
      ...state,
      isUpdateStatusExportProductRequest: false,
      isUpdateStatusExportProductSuccess: false,
      isUpdateStatusExportProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateStatusExportProduct]: (state) => ({
      ...state,
      isUpdateStatusExportProductRequest: false,
      isUpdateStatusExportProductSuccess: false,
      isUpdateStatusExportProductFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetExportProductState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
